import { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Member, MemberApi } from "../../apis/MemberApi";
import { ProductDiscountCreditApi, ProductDiscountCreditItem } from "../../apis/ProductDiscountCreditApi";
import { Loader } from "../../components/Loader/Loader";
import { useAuthenticatedUser } from "../../context/AuthContext";
import "./account-page.scss";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Details } from "./Details/Details";
import { Ledger } from "./Ledger/Ledger";
import { AffiliateProgram } from "./AffiliateProgram/AffiliateProgram";
import { Membership } from "./Membership/Membership";
import { StripeApi, Subscription } from "../../apis/StripeApi";
import { FamilyTree } from "./FamilyTree/FamilyTree";
import { CustomerApi } from "../../apis/CustomerApi";
import { OrderHistory } from "./OrderHistory/OrderHistory";
import { Matrix } from "./Matrix/Matrix";
import { QualificationApi } from "../../apis/QualificationApi";
import Stripe from "stripe";
import { PasswordEmailReset } from "./PasswordEmailReset/PasswordEmailReset";

interface AccountPageProps {}

interface Stage {
    text: string;
    to: StageTo;
    groupLock: boolean;
}

let navItems: Stage[] = [
    { text: "Account Details", to: "Account Details", groupLock: false },
    { text: "Membership / Billing", to: "Membership", groupLock: false },
    { text: "Affiliate Program", to: "Affiliate", groupLock: true },
    { text: "Product Discount Ledger", to: "Ledger", groupLock: true },
    { text: "Order History", to: "Order History", groupLock: false },
    { text: "Matrix", to: "Matrix", groupLock: true },
    { text: "Family Tree", to: "Family Tree", groupLock: true },
    { text: "Email & Password Reset", to: "Pass/EmailReset", groupLock: false },
];

export type StageTo = "Account Details" | "Ledger" | "Affiliate" | "Membership" | "Family Tree" | "Order History" | "Matrix" | "Pass/EmailReset";

export const AccountPage = ({}: AccountPageProps) => {
    const [loadingAccountPage, setLoadingAccountPage] = useState(true);
    const { authMember, updateAuthMember, logout } = useAuthenticatedUser();
    const [accountDetailsForm, setAccountDetailsForm] = useState<Member>();
    const [patchingProps, setPatchingProps] = useState<{ prop: string; value: string }[]>([]);
    const [pdcBalance, setPdcBalance] = useState(0);
    const [pdcLedger, setPdcLedger] = useState<ProductDiscountCreditItem[]>([]);
    const [subscriptions, setSubscriptions] = useState<Stripe.Product[]>([]);
    const [genealogyTree, setGenealogyTree] = useState<any[]>([]);
    const [matrixTree, setMatrixTree] = useState<any[]>([]);
    const [membership, setMembership] = useState<Stripe.Product>();
    const [isPrelaunch, setIsPrelaunch] = useState<boolean>(false);
    const [groupMembership, setGroupMembership] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>([]);
    const [orderHistory, setOrderHistory] = useState<any[]>([]);
    const [orderHistoryCount, setOrderHistoryCount] = useState(0);
    const [stage, setStage] = useLocalStorage<StageTo>("account-state", "Membership");
    const [offset, setOffset] = useState(0);
    const [groupMembers, setGroupMembers] = useState<Member[]>([]);
    const [subscriptionId, setSubscriptionId] = useState("");

    useEffect(() => {
        if (authMember && !accountDetailsForm) {
            getMember();
            getMembershipDetails();
            getFamilyTree();
            getMatrix();
        }
    }, [authMember]);

    useEffect(() => {
        if (authMember) {
            getProductDiscountCredits();
        }
    }, [authMember, loadingAccountPage]);

    useEffect(() => {
        getOrderHistory();
    }, [offset, authMember]);

    const getOrderHistory = () => {
        if (authMember) {
            setOrderHistory([]);
            setOrderHistoryCount(0);
            if (stage === "Order History") {
                setTimeout(() => {
                    setLoadingAccountPage(false);
                }, 500);
            }
            // MemberApi.OrderHistory(authMember.email, 15, offset)
            //     .then((response) => {
            //         if (response.success) {
            //             setOrderHistory(response.results);
            //             setOrderHistoryCount(response.resultCount);
            //         }
            //     })
            //     .catch(console.error)
            //     .finally(() => {
            //     });
        }
    };

    const getMember = () => {
        if (authMember) {
            MemberApi.GetOneMember(authMember.member_id)
                .then(setAccountDetailsForm)
                .catch(console.error)
                .finally(() => {
                    if (stage === "Account Details" || stage === "Pass/EmailReset") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getProductDiscountCredits = async () => {
        if (authMember) {
            ProductDiscountCreditApi.GetMemberBalance(authMember.member_id)
                .then((response: any) => {
                    if (response.success) {
                        setPdcBalance(response.results);
                    }
                })
                .catch(console.error);
            ProductDiscountCreditApi.GetMemberLedger(authMember.member_id)
                .then((response: any) => {
                    if (response.success) {
                        setPdcLedger(response.results);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Ledger") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getFamilyTree = async () => {
        if (authMember) {
            MemberApi.GetGenealogyTree(authMember.member_id)
                .then((response: any) => {
                    setGenealogyTree(response.results);
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Family Tree") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const getMatrix = async () => {
        if (authMember) {
            const _runDate = new Date();
            _runDate.setMonth(_runDate.getMonth() - 1);
            const month = _runDate.toLocaleDateString("default", { month: "short" });
            const year = _runDate.getFullYear().toString();
            QualificationApi.GetMatrixMemberTable(month, year, authMember.member_id)
                .then((response: any) => {
                    if (response.success) {
                        setMatrixTree(response.results);
                    } else {
                        setMatrixTree([]);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    if (stage === "Matrix") {
                        setTimeout(() => {
                            setLoadingAccountPage(false);
                        }, 500);
                    }
                });
        }
    };

    const handleSetDefaultPaymentMethod = async (customer_id: string, card_id: string) => {
        await CustomerApi.SetDefaultPaymentMethod(customer_id, card_id).catch(console.error);
    };

    const handlePaymentMethods = (paymentMethods: Stripe.PaymentMethod[], defaultCard: string, customer_id: string) => {
        const listOfPaymentMethods: Stripe.PaymentMethod[] = [];
        let paymentMethodToRemove: Stripe.PaymentMethod | undefined = undefined;
        for (let i = 0; i < paymentMethods.reverse().length; i++) {
            let paymentMethod = paymentMethods[i];
            let paymentMethodAlreadyExistsIndex = listOfPaymentMethods.findIndex(
                (payment) =>
                    payment.id === defaultCard &&
                    payment.card?.last4 === paymentMethod.card?.last4 &&
                    payment.card?.exp_month === paymentMethod.card?.exp_month &&
                    payment.card?.exp_year === paymentMethod.card?.exp_year
            );
            if (paymentMethodAlreadyExistsIndex === -1) {
                listOfPaymentMethods.push(paymentMethod);
            } else {
                if (paymentMethodToRemove === undefined) paymentMethodToRemove = paymentMethod;
            }
        }
        if (paymentMethodToRemove) {
            CustomerApi.RemovePaymentMethod(customer_id, paymentMethodToRemove.id);
        }

        return listOfPaymentMethods.reverse();
    };

    const getMembershipDetails = async () => {
        if (authMember) {
            setGroupMembership(false);
            await CustomerApi.GetCustomerSubDetails(authMember.customer_id).then((customerResponse) => {
                if (customerResponse) {
                    let defaultCard =
                        (customerResponse.customer && customerResponse.customer.default_payment_method !== null)
                            ? customerResponse.customer.default_payment_method.toString()
                            : customerResponse.paymentMethods.length > 0
                            ? customerResponse.paymentMethods[0].id
                            : "";
                    if (
                        customerResponse.customer &&
                        customerResponse.customer.default_payment_method === null &&
                        customerResponse.paymentMethods &&
                        customerResponse.paymentMethods.length > 0
                    ) {
                        handleSetDefaultPaymentMethod(authMember.customer_id, customerResponse.paymentMethods[0].id);
                    }
                    StripeApi.GetActiveSubscriptions()
                        .then((response) => {
                            let cleanPaymentMethods = handlePaymentMethods(customerResponse.paymentMethods, defaultCard, authMember.customer_id);
                            setSubscriptions(response.results);

                            let membership = response.results.find(
                                (sub: Stripe.Product) =>
                                    customerResponse.membership &&
                                    customerResponse.membership.plan &&
                                    sub.id === customerResponse.membership.plan.product
                            );
                            if (customerResponse.membership) {
                                setSubscriptionId(customerResponse.membership.id);
                            }
                            setMembership(membership);
                            setIsPrelaunch(
                                customerResponse.membership &&
                                    customerResponse.membership.metadata &&
                                    customerResponse.membership.metadata.preluanch_purchase
                                    ? true
                                    : false
                            );

                            if (
                                membership === undefined &&
                                (customerResponse.membership &&
                                    customerResponse.membership.metadata &&
                                    customerResponse.membership.metadata.preluanch_purchase) === undefined &&
                                customerResponse.valid_member
                            ) {
                                setGroupMembership(true);
                            }
                            setPaymentMethods(cleanPaymentMethods);
                            updateAuthMember({
                                ...authMember,
                                active: customerResponse.valid_member,
                                default_payment_method: defaultCard,
                            });
                        })
                        .finally(() => {
                            if (stage === "Membership" || stage === "Affiliate") {
                                setTimeout(() => {
                                    setLoadingAccountPage(false);
                                }, 500);
                            }
                        });

                    MemberApi.GetGroupMembers(authMember.member_id).then((response) => {
                        if (response.success) {
                            setGroupMembers(response.results);
                        }
                    });
                }
            });
        }
    };

    const updatePatchingProps = (property: string, value: any, remove: boolean = false) => {
        if (remove) {
            setPatchingProps(patchingProps.filter((object) => object.prop !== property));
        } else {
            const index = patchingProps.findIndex((obj) => obj.prop === property);
            if (index !== -1) {
                let copyOfPatchingProps = [...patchingProps];
                copyOfPatchingProps[index].value = value;
                setPatchingProps(copyOfPatchingProps);
            } else {
                setPatchingProps([...patchingProps, { prop: property, value }]);
            }
        }
    };

    return (
        <>
            {loadingAccountPage ? (
                <Loader />
            ) : (
                <>
                    {accountDetailsForm && (
                        <Container fluid className="py-0 py-md-4">
                            <Tab.Container id="account-page" defaultActiveKey={stage}>
                                <Row className="m-auto position-relative d-flex justify-content-center">
                                    <Col className="account-page__nav-item" sm={12} md={12} lg={4} xl={2}>
                                        <Nav className="flex-column">
                                            {navItems.map((navItem) => (
                                                <Nav.Item
                                                    className={`${
                                                        (groupMembership || (!membership && !isPrelaunch)) && navItem.groupLock ? "hidden-link" : ""
                                                    }`}
                                                    key={navItem.text}
                                                >
                                                    <Nav.Link onClick={() => setStage(navItem.to)} eventKey={navItem.to}>
                                                        {navItem.text}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                            <Nav.Item className="border-top account-page__nav-item-disabled">
                                                <Nav.Link>
                                                    Membership:{" "}
                                                    {authMember?.active ? (
                                                        <span className="tsn-green">ACTIVE</span>
                                                    ) : (
                                                        <span className="tsn-red">INACTIVE</span>
                                                    )}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col className="border-left" sm={12} md={12} lg={8} xl={8}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Account Details">
                                                <Details
                                                    accountDetailsForm={accountDetailsForm}
                                                    setAccountDetailsForm={setAccountDetailsForm}
                                                    updatePatchingProps={updatePatchingProps}
                                                    patchingProps={patchingProps}
                                                    setPatchingProps={setPatchingProps}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Ledger">
                                                <Ledger pdcBalance={pdcBalance} pdcLedger={pdcLedger} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={"Affiliate"}>
                                                <AffiliateProgram membership={membership} accountDetailsForm={accountDetailsForm} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Membership">
                                                <Membership
                                                    isPrelaunch={isPrelaunch}
                                                    subscriptions={subscriptions}
                                                    membership={membership}
                                                    paymentMethods={paymentMethods}
                                                    setPaymentMethods={setPaymentMethods}
                                                    setLoadingAccountPage={setLoadingAccountPage}
                                                    getMembershipDetails={getMembershipDetails}
                                                    handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                                                    groupMembers={groupMembers}
                                                    setGroupMembers={setGroupMembers}
                                                    groupMembership={groupMembership}
                                                    subscriptionId={subscriptionId}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Order History">
                                                <OrderHistory
                                                    limit={15}
                                                    setOffset={setOffset}
                                                    offset={offset}
                                                    orderHistory={orderHistory}
                                                    count={orderHistoryCount}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Family Tree">
                                                <FamilyTree genealogyTree={genealogyTree.filter((person) => person.membership_type_id != 4)} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Matrix">
                                                <Matrix matrixTree={matrixTree} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Pass/EmailReset">
                                                <PasswordEmailReset accountDetailsForm={accountDetailsForm} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};
