import { faCartShopping, faHeadphonesAlt, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const servicePromotions = [
    {
      id: 1,
      icon: faCartShopping,
      title: "Free Shipping",
      content: "Free delivery for every order",
    },
    {
      id: 2,
      icon: faHeadphonesAlt,
      title: "24/7 Customer Support",
      content: "Friendly 24/7 customer support",
    },
    {
      id: 3,
      icon: faShieldAlt,
      title: "Money Back Guarantee",
      content: "Products have a 30 day guarantee if you're not 100% satisifed",
    },
  ];

export default function ServiceBanner() {
  return (
    <div className="border-bottom">
      <div className="mb-4 mb-xl-5 pt-1 pb-5"></div>

      <section className="service-promotion container mb-md-4 pb-md-4 mb-xl-5">
        <div className="row">
          {servicePromotions.map((elm, i) => (
            <div key={i} className="col-md-4 text-center mb-5 mb-md-0">
              <div className="service-promotion__icon mb-4">
                <FontAwesomeIcon className="fs-1" icon={elm.icon as any} />
              </div>
              <h3 className="service-promotion__title h5 text-uppercase">
                {elm.title}
              </h3>
              <p className="service-promotion__content">
                {elm.content}
              </p>
            </div>
          ))}
        </div>
        {/* <!-- /.row --> */}
      </section>
      {/* <!-- /.service-promotion container --> */}

      <div className="pt-1 pb-5"></div>
    </div>
  );
}
