import React, { useEffect, useState } from "react";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { AuthApi } from "../../apis/AuthApi";
import { passMeetsRequirements } from "../../utils/string";
import { useNavigate } from "react-router-dom";
import { defaultErrorMessage, defaultSignUpForm, SignupForm } from "../SignUpPage/SignUpPage";
import { ProductItem } from "../ProductPage/ProductItem/ProductItem";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../context/AuthContext";
import BulaFitBanner from "../../assets/images/background/bulafitbanner.jpg";

const ChallengePage = () => {
    const navigate = useNavigate();
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [signUpForm, setSignUpForm] = useState<SignupForm>(defaultSignUpForm);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [howHeardValid, setHowHeardValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
    const [errorShow, setErrorShow] = useState(false);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);
    const { allProducts, increaseCartQuantity } = useShoppingCart();
    const [selectedPack, setSelectedPack] = useState<string | null>(null);
    const { authMember, setAuthMember, setIsLoggedIn } = useAuthenticatedUser();
    const [invalidAffiliateLink, setInvalidAffiliateLink] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleVerifyAffiliateLink();
    }, []);

    const handleVerifyAffiliateLink = () => {
        let display_id = localStorage.getItem("affiliate");
        const currentUrl = window.location;
        let path = currentUrl.pathname;
        path = path.replace("/account/challenge", "");
        path = path.replace("/account/challenge/", "");
        path = path.replace("/", "");
        if(path.length === 6) {
            display_id = path;
        }
        setErrorShow(false);

        if (display_id || path.length > 6) {
            if (path.length > 6) {
                setErrorMessage(`Note - '${path}' is not a valid afilliate link.`);
                setErrorShow(true);
                setInvalidAffiliateLink(true);
            } else {
                display_id &&
                    AuthApi.VerifyParentDisplayId(display_id)
                        .then((response) => {
                            if (response.success) {
                                setSignUpForm({ ...signUpForm, how_heard: response.parent_name });
                            } else {
                                setErrorMessage(`Note - '${display_id}' is not a valid afilliate link.`);
                                setSignUpForm({ ...signUpForm, how_heard: "" });
                                setErrorShow(true);
                                setInvalidAffiliateLink(true);
                            }
                        })
                        .catch(console.error);
            }
        }
    };

    useEffect(() => {
        if (authMember) {
            let challengeProduct = allProducts.find((product) => product.id === selectedPack);
            challengeProduct && increaseCartQuantity(challengeProduct);
            navigate("/products?openCart=true");
        }
    }, [authMember]);

    const handleSignUpClick = async () => {
        const first_name = signUpForm.first_name.length > 0;
        const last_name = signUpForm.last_name.length > 0;
        const how_heard = signUpForm.how_heard.length > 0;
        const phone = signUpForm.phone.length > 0;
        const email = signUpForm.email.length > 0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(signUpForm.email);
        const password = signUpForm.password.length > 0 && signUpForm.password === confirmPassword && passMeetsRequirements(signUpForm.password);
        const parent_id = localStorage.getItem("affiliate");
        setErrorShow(false);
        setEmailValid(email);
        setPhoneValid(phone);
        setFirstNameValid(first_name);
        setLastNameValid(last_name);
        setHowHeardValid(how_heard);
        setPasswordValid(password);

        if (first_name && last_name && email && password && how_heard && selectedPack && phone) {
            let copyOfSignUpForm = { ...signUpForm, forceCreate: true };
            copyOfSignUpForm.email = copyOfSignUpForm.email.toLowerCase();
            if (parent_id !== null) {
                copyOfSignUpForm.parent_display_id = parent_id;
            }
            let response = await AuthApi.RegisterChallenge(copyOfSignUpForm).catch(console.error);

            if (!response.success) {
                setEmailValid(false);
                setErrorMessage("The email you entered is already in our system.");
                setErrorShow(true);
                setLoadingSignup(false);
                return;
            } else {
                let loginResponse = await AuthApi.Login(signUpForm.email.toLowerCase().trim(), signUpForm.password.trim(), "").catch(console.error);
                localStorage.setItem("token", loginResponse.token);
                delete loginResponse.token;
                localStorage.setItem("member", JSON.stringify({ ...loginResponse, active: false }));
                localStorage.setItem("member-set", "true");
                setAuthMember({ ...loginResponse, active: false });
                setIsLoggedIn(true);
            }
        } else {
            if (!selectedPack) {
                setErrorMessage("Please select one of the BulaFit Packs to continue registering.");
            } else if (!first_name || !last_name || !email || !how_heard || !password || !phone) {
                if (!first_name || !last_name || !how_heard || !phone) {
                    setErrorMessage(defaultErrorMessage);
                } else if (!email) {
                    setErrorMessage("Please use a well formatted email address.");
                } else {
                    setErrorMessage("Password fields don't meet requirements or aren't a match.");
                }
            }
            setErrorShow(true);
        }
        setLoadingSignup(false);
    };

    const handleKeyDownSignUp = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setLoadingSignup(true);
            handleSignUpClick();
        }
    };

    return (
        <>
            <div id="wrapperMainContainer">
                <section className="mt-4">
                    <div className="container sign-up-container">
                        <div className="row justify-content-center align-items-top flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column">
                            <div className="col-12 col-lg-11 mb-2">
                                <div className="alert fs-6 alert-info" role="alert">
                                    <div className="mb-1 h2 fw-bold d-flex justify-content-center">Join the BulaFit Challenge!</div>
                                    <div className="text-center">
                                        If you're already a member with TopShelfNutra, login and go to the products page to purchase your BulaFit
                                        pack.
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 col-md-12 col-lg-4 order-lg-1 order-2">
                                <div className="mb-lg-4 mb-5">
                                    <div className="border-bottom border-top py-2 mb-1 fs-4 fw-bold">
                                        1) Select your BulaFit Pack
                                    </div>
                                </div>
                                <div className="d-md-flex">

                                    {/* First 2 are testing */}
                                    {allProducts
                                        .filter((product) => product.id === "prod_RTjv4nDsDNVaED" || product.id === "prod_RTjwQAF9EDUygw"
                                         || product.id === "prod_RTkrAmOgfRMrlv" || product.id === "prod_RTkr7AJWSgqKeR")
                                        .map((product, i) => (
                                            <ProductItem
                                                setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
                                                key={product.id}
                                                product={product}
                                                challenge={{
                                                    setSelectedPack,
                                                    selectedPack,
                                                }}
                                                i={i}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 col-md-12 offset-lg-1 col-lg-4 order-lg-2 order-2">
                                <div className="wrapper-signup-form mb-5">
                                    <div className="mb-lg-4 mb-5">
                                        <div className="border-bottom border-top py-2 mb-1 fs-4 fw-bold">
                                            2) Register for a TopShelfNutra Account
                                        </div>
                                    </div>
                                    <img src={BulaFitBanner} height={205} width={"100%"} />
                                    <div className={`my-4 text-center ${errorShow ? "tsn-red" : "tsn-white"}`}>{errorMessage}</div>
                                    <div className="row mb-3 g-3">
                                        <div className="col-12 container-how-heard">
                                            <BootstrapInput
                                                value={signUpForm.how_heard}
                                                onKeyDown={handleKeyDownSignUp}
                                                onChange={(e) => setSignUpForm({ ...signUpForm, how_heard: e.target.value })}
                                                label="Let us know who referred you"
                                                valid={howHeardValid}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <BootstrapInput
                                                value={signUpForm.first_name}
                                                onKeyDown={handleKeyDownSignUp}
                                                onChange={(e) => setSignUpForm({ ...signUpForm, first_name: e.target.value })}
                                                label="First name"
                                                valid={firstNameValid}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <BootstrapInput
                                                value={signUpForm.last_name}
                                                onKeyDown={handleKeyDownSignUp}
                                                onChange={(e) => setSignUpForm({ ...signUpForm, last_name: e.target.value })}
                                                label="Last name"
                                                valid={lastNameValid}
                                            />
                                        </div>
                                        <div className="col-6">
                                                <BootstrapInput
                                                    value={signUpForm.email}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
                                                    label="Email"
                                                    valid={emailValid}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <BootstrapInput
                                                   type="phone"
                                                   value={signUpForm.phone}
                                                   onKeyDown={handleKeyDownSignUp}
                                                   onChange={(e) => setSignUpForm({ ...signUpForm, phone: e.target.value })}
                                                   label="Phone Number"
                                                   valid={phoneValid}
                                                />
                                            </div>
                                        <div className="col-12">
                                            <div className="password-field position-relative">
                                                <BootstrapInput
                                                    value={signUpForm.password}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, password: e.target.value })}
                                                    label="Password"
                                                    valid={passwordValid}
                                                    type="password"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <BootstrapInput
                                                value={confirmPassword}
                                                onKeyDown={handleKeyDownSignUp}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                label="Confirm Password"
                                                valid={passwordValid}
                                                type="password"
                                            />
                                        </div>
                                        <div className="col-12 mt-2 me-4 mb-4 text-center">
                                            <div className="fs-6 fw-lighter ">
                                                {" "}
                                                <span className="fw-bold">Password requirements:</span> minimum 8 characters, at least one uppercase
                                                letter, one number, and one special character.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-grid my-9">
                                        <div className="col-12 d-grid">
                                            <BootstrapButton
                                                disabled={invalidAffiliateLink}
                                                variant="secondary"
                                                title="Register"
                                                loading={loadingSignup}
                                                onClick={() => {
                                                    setLoadingSignup(true);
                                                    handleSignUpClick();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default ChallengePage;
