/* eslint-disable react/prop-types */

import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ShareComponent({ title = "title" }) {
    const { pathname } = useLocation();

    const [fullUrl, setFullUrl] = useState("");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const currentUrl = `${window.location.protocol}//${window.location.host}${pathname}`;
            setFullUrl(currentUrl);
        }
    }, []);

    const shareContent = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: title,
                    url: fullUrl,
                });
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.log("Web Share API not supported in this browser.");
        }
    };
    return (
        <div onClick={shareContent} className="cursor-pointer d-flex align-items-center">
            <FontAwesomeIcon icon={faShare} className="fs-6 mx-2" />
            <span className="fs-6">Share Product</span>
        </div>
    );
}
