import { Col, Container, Row } from "react-bootstrap";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { Member } from "../../../apis/MemberApi";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import {
    curr_subscriptions,
    production_group_owner_forty,
    production_group_owner_ten,
    production_group_owner_twenty,
    test_group_owner_forty,
    test_group_owner_ten,
    test_group_owner_twenty,
} from "../../../App";
import QRCode from "react-qr-code";

interface AffiliateProgramProps {
    accountDetailsForm: Member;
    membership: Stripe.Product | undefined;
}

export const AffiliateProgram = ({ accountDetailsForm, membership }: AffiliateProgramProps) => {
    const groupMembershipIds = [
        test_group_owner_ten,
        test_group_owner_twenty,
        test_group_owner_forty,
        production_group_owner_ten,
        production_group_owner_twenty,
        production_group_owner_forty,
    ];

    const copyAffiliateLinkToClipboard = (groupMember: boolean) => {
        if (accountDetailsForm) {
            navigator.clipboard.writeText(`${window.location.origin}/${groupMember ? "gm_" : ""}${accountDetailsForm.display_id}`);
        }
    };

    return (
        <Container fluid className="stage-container">
            <Row className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-affiliate d-block">
                <div className="fs-4 mb-4 fw-bold">Affiliate Program</div>
                <Row>
                    <Col md={6} className="col-12 mb-3 d-flex flex-column justify-content-center align-items-center">
                        <div className="fs-6 mb-2 fw-bold">Affiliate Link</div>
                        <div className="d-flex flex-column align-items-center w-100">
                            <div style={{ height: "auto", maxWidth: 225, width: "100%" }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`${window.location.origin}/account/signup/${accountDetailsForm.display_id}`}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="mt-4">
                                <BootstrapButton
                                    onClick={() => copyAffiliateLinkToClipboard(false)}
                                    title="Copy Affiliate Link to Clipboard"
                                    variant="outline-primary"
                                />
                            </div>
                            {groupMembershipIds.some((id) => id === membership?.id) && <p>Copy your Affiliate Link to give to anyone you want to refer! They sign-up and subscribe to a membership and you get Product Discount Credits (PDC's).</p>}
                        </div>
                    </Col>
                    {groupMembershipIds.some((id) => id === membership?.id) && (
                        <Col md={6} className="col-12 mb-3 d-flex flex-column justify-content-center align-items-center">
                            <div className="fs-6 mb-2 fw-bold">Group Link</div>
                            <div className="d-flex flex-column align-items-center w-100">
                                <div style={{ height: "auto", maxWidth: 225, width: "100%" }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={`${window.location.origin}/account/signup/gm_${accountDetailsForm.display_id}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <div className="mt-4">
                                    <BootstrapButton
                                        onClick={() => copyAffiliateLinkToClipboard(true)}
                                        title="Copy Group Link to Clipboard"
                                        variant="outline-primary"
                                    />
                                </div>
                            </div>
                            <p>Copy your Group Link to give to anyone you want to have Membership pricing for free. They sign-up and are granted access to your membership and recieve Membership pricing on their account.</p>
                        </Col>
                    )}
                </Row>

                <div className="mt-4 mb-4"></div>

                <div className="fs-4 fw-bold border-bottom pb-2 mt-3 mb-3">Referral Progam Explained</div>
                <p className="fs-6">
                    Ask anyone and they will agree that personal referrals are the best form of marketing. Personally, we won’t try a new restaurant,
                    new product, or go to a movie without a referral from someone we trust. It just isn’t worth a bad meal or to sit through a
                    terrible movie – so we ask someone about it first. TopShelfNutra is founded on this principle. We don’t invest heavily in
                    advertising. Instead, we formulate and offer the best quality products, the best value for your dollar, and we give you an added
                    incentive for referring your friends. Yep, that’s how it works.{" "}
                    <span className="fw-bold">
                        If you love us, tell others! When they fall in love with our products, they’ll tell others and everyone wins!
                    </span>
                </p>

                <Row>
                    <Col sm={12} md={6} lg={8} className="col-12">
                        <span className="fs-4 fw-bold">What are the details?</span>
                        <br />
                        <div className="fs-6">
                            Each time you refer a friend who becomes a TopShelfNutra Member, you will receive 5 monthly product discount credits
                            (PDC’s) in your personal account that can be applied to your next product order. Your friend will receive a one-time 5 PDC
                            credit as our welcome gift.
                        </div>
                        <div className="my-4 fs-6">
                            There is no limit to the number of referrals and product discount credits (PDC’s) you can generate. The more people you
                            refer, the more product discount credits (PDC’s) you receive!
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="col-12 alert alert-info fs-6" role="alert">
                        <span className="fs-4 fw-bold">Highlights</span>
                        <br />
                        <p className="fs-6">Refer friends, get PDC's which can be used to purchase product.</p>
                        <p className="fs-6">
                            Get 3 friends with active subscriptions each month and offset your monthly subscription in free products!
                        </p>
                    </Col>
                </Row>

                <div className="d-flex my-6">
                    <div className="me-2">
                        <span className="fs-4 fw-bold">The referral PDC’s continue beyond your own referrals.</span>
                        <div className="fs-6">
                            <p>
                                If your referrals also engage in our referral program and refer others who become TopShelf Nutra Members, they will
                                receive their 5 PDC’s and you will also receive 2.5 PDC’s because their referrer was originally referred by you.
                            </p>
                            <p></p>These referral Product Discount Credits (PDC’s) continue through{" "}
                            <span className="fw-bold">6 different referrals</span> <p></p>
                            <table className="table table-bordered fs-6 mx-auto p-2" style={{ width: "300px" }}>
                                <thead className="border-bottom">
                                    <tr>
                                        <th>Level</th>
                                        <th>PDC's</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Level 1</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td>Level 2</td>
                                        <td>2.50</td>
                                    </tr>
                                    <tr>
                                        <td>Level 3</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Level 4</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Level 5</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Level 6</td>
                                        <td>1</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                The hope is that we all will be able to generate recurring product discount credits that will allow our families and
                                others to access our amazing health formulas at discounts they’ve never before seen.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="fs-4 fw-bold my-6">We are trying to ease the pain points found throughout our industry.</div>
                <div className="fs-6">
                    Many programs we’ve seen include high entry fees to access wholesale pricing.{" "}
                    <span className="fw-bold">We give below wholesale pricing to all of our subscribers.</span> Other programs require a large first
                    order or monthly minimums. <span className="fw-bold">TopShelfNutra wants you to buy what you want when you want it.</span> We have
                    also experienced how referral programs are often only rewarding to those people who can refer a large number of people.{" "}
                    <span className="fw-bold">
                        Our TopShelfNutra Referral Program rewards you for your first and every referral right from the start!
                    </span>{" "}
                    Lastly, we have experienced how companies use shipping as a way to increase their profit by charging even more than what the
                    shipping costs are. <span className="fw-bold">TopShelfNutra subscribers don’t pay for shipping ever!</span>
                    <p className="my-6">
                        So long as people remain subscribers and pay their renewal fee upon or before their renewal date each month, their product
                        discount credits will accumulate in their account and be available upon checkout to apply to product orders. PDC’s reduce the
                        overall price paid on product orders before taxes on a $1 equals 1PDC basis. Upon checkout, PDC’s in your account can be
                        applied to the cost of your product order before taxes are applied. Subscriptions are charged in advance of the next month.
                        Any subscriber who cancels their subscription or their credit card on file fails to charge, will have 30 days after their
                        renewal date to claim their remaining PDC’s or make payment arrangements for their ongoing subscription renewal. If the
                        subscriber’s account is not renewed and the subscription fee paid, any remaining PDC’s will be relinquished 30 days after
                        their attempted renewal date.
                    </p>
                    <p className="my-6">
                        The vast amount of PDC’s earned by subscribers will be applied as discounts on product orders placed each month. TopShelfNutra
                        does not expect its subscribers to accumulate high levels of PDC’s in their accounts for extended periods of time, rather we
                        expect most subscribers to apply their discounts to their monthly product orders. To ensure TopShelfNutra will not accrue high
                        levels of PDC liabilities in subscriber accounts, if a subscriber’s PDC account balance exceeds 200 PDC’s, the subscriber will
                        be eligible for a cashout. Cashout requests must be submitted thru the subscribers account ledger. No cashout request will be
                        accepted by email or phone. Cashouts of PDC’s create a taxable event and will require the subscriber to enroll as an
                        Independent Marketing Representative (IMR) for TopShelfNutra. Upon request, TopShelfNutra will send the subscriber an
                        Independent Marketing Representative Agreement. Upon acceptance of such agreement, TopShelfNutra will send the IMR payment in
                        the form of a check less the disbursement fee. The check disbursement fee is $2.50 per check and will be deducted
                        automatically from the total PDC redemption request. Check disbursements are available on a once per month basis. All IMR
                        earnings that meet the federal limit will be reported to the IRS and will receive a FORM1099 from TopShelfNutra following the
                        annual tax year.{" "}
                    </p>
                </div>

                <div className="d-flex flex-column fs-6">
                    <div className="fw-bold">Notes:</div>
                    <ul className="d-none d-lg-block my-4">
                        <li>Active subscriber is defined as an individual who has paid the monthly subscription in a given calendar month.</li>
                        <li>
                            Product Discount Credits (PDC’s) are accumulated monthly for active subscribers only and made available to personal
                            accounts by the 10th of each month.
                        </li>
                        <li>TopShelfNutra reserves the right to accept or not accept IMR Applications according to US law and regulations.</li>
                        <li>Product pricing is determined solely by TopShelfNutra and is subject to change.</li>
                        <li>
                            Pricing is according to and including but not limited to the cost of ingredients, production, storage, Product Discount
                            Credits (PDC’s), shipping and handling and can be changed at any time and with the sole discretion of TopShelfNutra.
                        </li>
                        <li>
                            TopShelfNutra also retains the right to modify the TopShelfNutra Referral Program as it deems necessary to continue it’s
                            good business practices.
                        </li>
                    </ul>
                    <div className="d-block d-lg-none d-flex flex-column">
                        <div className="mt-2">
                            Active subscriber is defined as an individual who has paid the monthly subscription in a given calendar month.
                        </div>
                        <div className="mt-2">
                            Product Discount Credits (PDC’s) are accumulated monthly for active subscribers only and made available to personal
                            accounts by the 10th of each month.
                        </div>
                        <div className="mt-2">
                            TopShelfNutra reserves the right to accept or not accept IMR Appdivcations according to US law and regulations.
                        </div>
                        <div className="mt-2">Product pricing is determined solely by TopShelfNutra and is subject to change.</div>
                        <div className="mt-2">
                            Pricing is according to and including but not divmited to the cost of ingredients, production, storage, Product Discount
                            Credits (PDC’s), shipping and handdivng and can be changed at any time and with the sole discretion of TopShelfNutra.
                        </div>
                        <div className="mt-2">
                            TopShelfNutra also retains the right to modify the TopShelfNutra Referral Program as it deems necessary to continue it’s
                            good business practices.
                        </div>
                    </div>
                </div>

                <div className="d-flex mt-2 d-block d-md-none my-10"></div>
            </Row>
        </Container>
    );
};
