import { useEffect, useState } from "react";
import "./family-tree.scss";
import { Container, Row } from "react-bootstrap";

interface FamilyTreeProps {
    genealogyTree: any[];
}

export const FamilyTree = ({ genealogyTree }: FamilyTreeProps) => {
    const [downline, setDownline] = useState({ l: 1, c: 0 });
    let level = 0;
    let count = -1;

    useEffect(() => {
        TreeData(genealogyTree, "#wrapperTree");
    }, []);

    function TreeData(data: any, select: any) {
        var main = document.querySelector("#wrapperMainContainer");
        if (!document.querySelector(".treeCustom")) {
            var treecanvas = document.createElement("div");
            treecanvas.className = "treeCustom";
            if (data.length > 0) {
                var treeCode = buildTree(data, data[0]);
                treecanvas.innerHTML = treeCode;
                if (main) {
                    main.appendChild(treecanvas);
                    setDownline({ l: level, c: count });
                }
            }
        }
    }

    function buildTree(obj: any, node: any) {
        var _name = node.first_name.charAt(0);
        let iconBadge = '<span class="badge bg-warning" title="Signed Up">S</span>';
        if (_name.length > 0) _name += ". ";
        _name += `${node.last_name} ${node.membership_type_id}`;

        var _titleString = "Signed Up";
        var _classString = "";
        if (node.parent_id == null) _classString = "root ";
        if (node.first_membership_purchased_at) {
            _classString += "text-success fw-bold paid-membership-account";
            _titleString = "Membership Purchased";
            iconBadge = '<span class="badge bg-success" title="Purchased Membership">P</span>';
            if(level !== 6) {
                count = count += 1;
            }
        } else if (node.verified_on) {
            _classString += "text-primary verified-account";
            _titleString = "Account Verified";
            iconBadge = '<span class="badge bg-primary" title="Verified">V</span>';
        }
        var _nodeBadge = '<span class="opacity-50">' + iconBadge + "</span>";

        var treeString =
            '<li class="" title="' +
            _titleString +
            '"><a class="cursor-default fs-6 generic-member-node ' +
            _classString +
            '" data-MemberId="' +
            node.member_id +
            '" role="button">' +
            _name +
            " " +
            _nodeBadge +
            '<div class="mt-1">(' +
            node.display_id +
            ")</div></a>";

        var _children = [];
        for (var i in obj) {
            if (obj[i].parent_id == node.member_id) _children.push(obj[i]);
        }
        if (_children.length > 0) {
            treeString += "<ul>";
            for (var i in _children) {
                if(level < _children[i].generation) {
                    if(level !== 7) {
                        level = _children[i].generation;
                    }
                }
                treeString += buildTree(obj, _children[i]);
            }
            treeString += "</ul>";
        }

        return treeString;
    }

    return (
        <>
            <Container fluid className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-affiliate d-block family-tree-container">
                <Row className="py-6 wrapper-details">
                    <span className="fs-4 mb-4 fw-bold">Family Tree</span>
                    <div className="fs-5 mt-2">
                        The family tree is a visual representation of your network of referrals. Each person you refer is placed in your tree and each
                        person they refer is placed in their tree. The family tree is a great way to see how your referrals are growing and how your
                        network is expanding. Wider family trees will need to be scrolled, hovering over a referral will highlight their network of
                        referrals. Smaller screens show the tree as an indented list for easier viewing.
                    </div>
                    <div className="fs-6 mt-2">
                        <span className="fw-bold">Legend:</span>
                        <div className="d-flex flex-column">
                            <div>
                                <span className="badge bg-warning">S</span> - Signed Up
                            </div>
                            <div>
                                <span className="my-1 badge bg-secondary">V</span> - Account has been Verified
                            </div>
                            <div>
                                <span className="badge bg-success">P</span> - Purchased Membership
                            </div>
                        </div>
                    </div>
                    {downline.l - 1 > 0 && <div className="mt-2 downline_container fs-5">
                        In Levels 1-{downline.l - 1}, you have {downline.c} people with active memberships.
                    </div>}
                </Row>

                <div id="wrapperMainContainer" className="mt-3 border-top wrapper-unilevel-tree ps-2 pe-2 pt-2 pb-0 d-block">
                    <div id="wrapperTree" className="wrapper-tree mt-2 pt-4"></div>
                </div>
            </Container>
        </>
    );
};
