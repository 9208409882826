import React, { useEffect, useState } from "react";
import { Member, MemberApi } from "../../apis/MemberApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

type GroupMemberListItemProps = {
    member: Member;
    groupMembers: Member[];
    setGroupMembers: React.Dispatch<React.SetStateAction<Member[]>>;
};

export const GroupMemberListItem = ({ member, groupMembers, setGroupMembers }: GroupMemberListItemProps) => {
    const [confirmState, setConfirmState] = useState(false);

    useEffect(() => {
        if (confirmState === true) {
            setTimeout(() => {
                setConfirmState(false);
            }, 3000);
        }
    }, [confirmState]);

    const removeMemberFromGroup = async () => {
        let response = await MemberApi.RemoveGroupMemberChild(member.member_id);
        if (response.success) {
            setGroupMembers(groupMembers.filter((gMember) => gMember.member_id !== member.member_id));
        }
    };

    return (
        <li className="list-group-item flex-column justify-content-between">
            <div className="my-2 d-flex justify-content-between">
                <div className="fw-bold">
                    {member.first_name} {member.last_name}{" "}
                    {member.verified_on && member.verified_on.length > 0 ? (
                        <span className="badge bg-success" title="Verified">
                            V
                        </span>
                    ) : (
                        <span className="badge bg-warning" title="Signed Up">
                            S
                        </span>
                    )}
                </div>
                <div className="mx-2">{new Date(member.created_at).toLocaleDateString()}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <div>{member.email}</div>
                {confirmState ? (
                    <FontAwesomeIcon
                        title={`Confirm removal of ${member.first_name} ${member.last_name}`}
                        className="fs-3 tsn-green confirm-member-icon"
                        icon={faCircleCheck}
                        onClick={removeMemberFromGroup}
                    />
                ) : (
                    <FontAwesomeIcon
                        title={`Click to remove ${member.first_name} ${member.last_name}`}
                        onClick={() => setConfirmState(true)}
                        className="fs-3 tsn-red delete-member-icon"
                        icon={faDeleteLeft}
                    />
                )}
            </div>
        </li>
    );
};
