import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Loader } from "../../components/Loader/Loader";
import { ProductItem } from "./ProductItem/ProductItem";
import "./product-page.scss";
import { MembershipInfoOffCanvas } from "../../components/MembershipInfoOffCanvas/MembershipInfoOffCanvas";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

interface ProductPageProps {}

export const ProductPage = ({}: ProductPageProps) => {
    const { allProducts, openCart } = useShoppingCart();
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedColView, setSelectedColView] = useState(4);
    const { authMember } = useAuthenticatedUser();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (allProducts.length > 0) {
            setTimeout(() => {
                setLoadingProducts(false);
            }, 500);
        }
    }, [allProducts]);

    useEffect(() => {
        if (location.search.includes("openCart=true")) {
            openCart();
            navigate("/products");
        }
    }, []);

    return (
        <>
            {loadingProducts ? (
                <Loader />
            ) : (
                <Container>
                    <h2 className="section-title text-uppercase fw-bold my-5">Products</h2>
                    <Row className="my-4">
                        <Col className="col-12" md={5}>
                            <BootstrapInput
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                label="Search"
                                placeholder="Type in a product name"
                            />
                        </Col>
                    </Row>

                    <div className={`products-grid row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-${selectedColView}`} id="products-grid">
                        {allProducts
                            .filter((product) => {
                                // Holiday e-gift card
                                if (
                                    product.id === "prod_RWND0mRoOot4y8" ||
                                    product.id === "prod_RKnFDT2FYYW9wE" ||
                                    product.id === "prod_RKnDK7U7sbY6ye"
                                ) {
                                    if (authMember && authMember.first_membership_purchased_at !== null) {
                                        return product;
                                    }
                                    // Bulafit packs
                                    //First two are testing mode ones.
                                } else if (product.id === "prod_RTjwQAF9EDUygw" || product.id === "prod_RTjv4nDsDNVaED" 
                                    || product.id === "prod_RTkrAmOgfRMrlv" || product.id === "prod_RTkr7AJWSgqKeR") {
                                    if (authMember) {
                                        return product;
                                    }
                                    // Only Bulafit enrollment
                                } else if (product.id === "prod_RWNPqL0G85qlMa" || product.id === "prod_RWND0mRoOot4y8") {
                                    if(authMember && authMember.active) {
                                        return product;
                                    }
                                    // Monthly 60 day membership
                                } else if (product.id === "prod_QMplKEph6gdyDi" || product.id === "prod_RTkuio8RiPFF0D") {
                                    // Don't show at all.
                                } else {
                                    return product;
                                }
                            })
                            .filter((product) => {
                                if (searchTerm.length === 0) {
                                    return product;
                                } else {
                                    if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return product;
                                    }
                                }
                            })
                            .sort((a, b) => {
                                if (!a.is_coming_soon && b.is_coming_soon) return -1;
                                if (a.is_coming_soon && !b.is_coming_soon) return 1;
                                return 0;
                            })
                            .map((elm, i) => (
                                <ProductItem key={elm.id} product={elm} i={i} setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow} />
                            ))}
                    </div>
                    <MembershipInfoOffCanvas
                        membershipInfoOffCanvasShow={membershipInfoOffCanvasShow}
                        setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
                    />
                </Container>
            )}
        </>
    );
};
