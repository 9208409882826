import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { useAuthenticatedUser } from "../../context/AuthContext";
import "./landing-page.scss";
import { AuthApi } from "../../apis/AuthApi";
import ServiceBanner from "../../components/ServiceBanner/ServiceBanner";
import SplitSectionBanner from "../../components/SplitSectionBanner/SplitSectionBanner";

interface LandingPageProps {
    setSignInOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LandingPage = ({ setSignInOffCanvasShow }: LandingPageProps) => {
    const { isLoggedIn, logout } = useAuthenticatedUser();
    const [affiliatePersonExists, setAffiliatePersonExists] = useState({ name: "", groupMembership: false });
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        handleVerifyAffiliateLink();
    }, []);

    const handleVerifyAffiliateLink = () => {
        const currentUrl = window.location;
        let path = currentUrl.pathname;
        path = path.replace("/account/signup", "");
        path = path.replace("/account/signup/", "");
        path = path.replace("/", "");

        if (path.length === 6 || (path.length === 9 && path.includes("gm"))) {
            logout("");
            AuthApi.VerifyParentDisplayId(path.length === 9 ? path.slice(3) : path)
                .then((response) => {
                    setAffiliatePersonExists({ name: response.parent_name, groupMembership: path.includes("gm") });
                })
                .catch(console.error);
        }
    };

    // Old page

    return (
        <div className="landing-page">
            <div className="rounded-3 w-100">
                <div className="d-flex flex-column justify-content-center align-items-center container-fluid landing-page__landing_hero py-5 hero">
                    <h1 className="display-5 fw-bold">Welcome to TopShelfNutra</h1>
                    <p className=" fs-4"> All of your favorite nutritionals at a price that makes you smile!</p>
                    {affiliatePersonExists.name.length > 0 && (
                        <>
                            <Container className="d-none d-lg-block">
                                <div className="d-flex mt-4 w-100 d-block justify-content-center">
                                    {affiliatePersonExists.groupMembership ? (
                                        <div className="alert alert-info lb-welcome fs-5" role="alert">
                                            Become a Member by joining the {affiliatePersonExists.name} Group by clicking the <b>Become a Member</b>{" "}
                                            button below!
                                        </div>
                                    ) : (
                                        <div className="alert alert-info lb-welcome fs-5" role="alert">
                                            Looks like <b>{affiliatePersonExists.name}</b> wanted you to look around. Take a walk through the products
                                            page and when you are ready and want to be part of the family, click the <b>Become a Member</b> button and
                                            follow the simple three step process.
                                        </div>
                                    )}
                                </div>
                            </Container>
                            <Container className="mt-4 d-block d-lg-none">
                                <div className="d-flex mt-4 w-100 d-block justify-content-center">
                                    <div className="alert alert-info lb-welcome" role="alert">
                                        {affiliatePersonExists.groupMembership ? (
                                            <div className="alert alert-info lb-welcome fs-5" role="alert">
                                                Become a Member by joining the {affiliatePersonExists.name} Group by clicking the{" "}
                                                <b>Become a Member</b> button below!
                                            </div>
                                        ) : (
                                            <div className="alert alert-info lb-welcome fs-5" role="alert">
                                                Looks like <b>{affiliatePersonExists.name}</b> wanted you to look around. Take a walk through the
                                                products page and when you are ready and want to be part of the family, click the{" "}
                                                <b>Become a Member</b> button and follow the simple three step process.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Container>
                        </>
                    )}
                    {!isLoggedIn && (
                        <>
                            <div>
                                <BootstrapButton
                                    onClick={() => navigate("/account/signup/")}
                                    className="btn-lg"
                                    title={
                                        affiliatePersonExists.groupMembership ? "Become a Member for FREE" : "Become a Member for only $14.95 monthly"
                                    }
                                    variant="secondary"
                                />
                                <div className="fs-5">to access member pricing and FREE SHIPPING!</div>
                            </div>
                            <a onClick={() => setSignInOffCanvasShow(true)} className="mt-3 tsn-white cursor-pointer">
                                Already a member? Sign In
                            </a>
                        </>
                    )}
                    {!isLoggedIn && (
                        <>
                            <div className="my-4">
                                <b>OR</b>
                            </div>
                            <BootstrapButton
                                onClick={() => navigate("/account/challenge/")}
                                className="btn-lg"
                                title="Join the BulaFit Challenge"
                                variant="secondary"
                            />
                        </>
                    )}
                </div>
            </div>
            <ServiceBanner />
            <section className="tsn-bg-light-gray">
                <Container className="p-4">
                    <h1 className="fs-3 fw-bold">Why TopShelfNutra?</h1>
                    <p className="fs-6 mt-3">
                        Many health programs and products we love have been priced out of our budgets. Also, to save money, suppliers are regularly
                        changing their formulas to cheaper and inferior ingredients, resulting in less positive results at the same dosages. On top of
                        these challenges, shipping charges continue to increase and add even more cost to our orders.
                    </p>
                    <p className="fs-6 mt-2">
                        TopShelfNutra solves these and many more problems. We have been formulating, manufacturing, and distributing high-end health
                        products around the globe for over 30 years. We know first-hand from our many customers which products work and for whom and
                        for what conditions. We are so excited to offer these amazing formulas that have been changing lives for decades at prices
                        never seen before! Finally, the entire family can have access to the finest nutrition at pricing that used to be only for an
                        individual.
                    </p>
                    <p className="fs-6 mt-2">
                        How do we offer such amazing value to our customers? The answer is manifold. We have larger purchasing power. We produce our
                        products in larger quantities and in streamlined and less expensive packaging. We are the manufacturer and ship directly to
                        the consumer. We have negotiated lower shipping rates and so your{" "}
                        <span className="fw-bold">monthly subscription grants you unlimited FREE Shipping</span>. We keep our overhead expenses low
                        and have no middlemen. Instead of hefty advertising and marketing budgets, we rely on “word of mouth” referrals from our happy
                        customers.
                    </p>
                    <p className="fs-6 mt-2">
                        In the end, it is an improved model. You get better quality, better results, better value, and better convenience. Order what
                        you want, when you want it, delivered to where you want to receive it. Refer your friends and family and earn Product Discount
                        Credits every month that can lower your prices even more!
                    </p>
                </Container>
            </section>
            <SplitSectionBanner groupMembership={affiliatePersonExists.groupMembership} />
            <section className="tsn-bg-light-gray border-top">
                <Container>
                    <Row className=" border-radius">
                        <Col className="p-4">
                            <h1 className="fs-3 fw-bold">Refund Policy</h1>
                            <div className="d-flex">
                                <div className="d-flex flex-column">
                                    <h5>For Product Purchases:</h5>
                                    <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                        <span>
                                            All TopShelfNutra products have a 30 day guarantee. If you are not 100% satisfied with any of our products
                                            for any reason, simply send back the unused product to:
                                        </span>
                                    </p>
                                    <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                        <span>TopShelfNutra Returns</span>
                                        <br />
                                        <span>282 S 671 W</span>
                                    </p>
                                    <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                        <span>Pleasant Grove, UT 84062</span>
                                    </p>
                                    <p style={{ marginBottom: "0pt", lineHeight: "normal" }}>
                                        <span>&nbsp;</span>
                                    </p>
                                    <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                        <span>Be sure to include your name that was on the order, or your order ID number.</span>
                                    </p>
                                    <h5>For Membership Purchases:</h5>
                                    <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                        <span>
                                            TopShelfNutra monthly membership charges can be refunded up to 7 days from the date of the charge if there
                                            were no product purchases used with the membership.
                                        </span>
                                    </p>
                                    <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                        <span>
                                            TopShelfNutra yearly membership charges will be refunded 100% if requested within 7 days and if there were
                                            no product purchases used with the membership.
                                        </span>
                                    </p>
                                    <p style={{ marginBottom: "14pt", lineHeight: "normal" }}>
                                        <span>To request a refund of a TopShelfNutra membership, simply send an email to</span>
                                        <span>&nbsp;</span>
                                        <a href="mailto:support@topshelfnutra.com" style={{ textDecoration: "none" }}>
                                            <u>
                                                <span>support@topshelfnutra.com</span>
                                            </u>
                                        </a>
                                        <span>&nbsp;</span>
                                        <span>with the name and/or ID number on the account.</span>
                                    </p>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};
