import React from "react";
import "./about-page.scss";
import { Container } from "react-bootstrap";

interface AboutPageProps {}

export const AboutPage = ({}: AboutPageProps) => {
    return (
        <Container className="my-4">
            <h1 className="section-title text-uppercase fw-bold my-5">Our Story</h1>
            <p>
                <h5>Our Start</h5> in nutrition began when my father told me that he and my mother had been using certain health products, and they were
                noticing real improvements as a result. They gave me some to try and said, these products are different. They really work! I soon
                began marketing health products, and I still remember my dad saying to me, “You need to be careful in the nutritional supplement
                market – there are lots of snake oil salesmen. Always be confident that what you are selling works, and you never have to worry what
                people will think of you.”
            </p>
            <p>
                That became the start of my next thirty years in the nutritional industry. We’ve seen so many health products come and go. We’ve tried
                many and watched many others do the same. Some products seem to gain rapid popularity and then get replaced by the next new thing.
                Time eventually proves which products give continual benefit to the consumers.
            </p>
            <p>
                <h5>Our Interest</h5> is in the specific nutrients and formulas that continue to bring lasting health benefits and real results over time. What
                are they? Why do they work? What are the active agents in the formula? Can they be reformulated to work better, taste better, be more
                convenient and thereby more beneficial to people?
            </p>
            <p>
                What products truly bring improvement to life? Medical and scientific research are very valuable, but they will never replace actual
                real-life application. Many products are formulated to include the marketing language and ingredients to convince people to buy the
                products, but don’t bring about the health improvements touted. Medical and scientific research are helpful to identify potential
                benefits and problems with nutritional ingredients, but they should always be tried and tested on people to ensure that the forms of
                the ingredients and their formulations and deliveries are supported by results.
            </p>
            <p>
                <h5>Our Mentor</h5> led us to the research done in veterinary medicine. This can seem strange to many at first, looking to veterinary medicine
                to learn about human health. The main reason is that especially in agricultural applications, veterinarians don’t have access to large
                medical insurance programs and treatments. The animal patients have a price tag on them. A pig, or chicken, or cow are only worth so
                much. Thereby, there is a limit on what can be spent treating a disease or health problem with these animals. More often, the
                treatment is more expensive than the price of the animal, so the farmer chooses to put the animal down and start over. Veterinarians
                in the agricultural animal space must place their emphasis on keeping the animal herds healthy from the start. They implement
                nutritional programs to prevent illness and disease to increase the farmers’ overall profitability. What can be added to animal feeds
                and water to increase their healthiness, size, and weight? Can these additional nutritional formulas increase the likelihood of
                survival of new offspring? By increasing survivability, they again increase the farmers’ productivity and profit.
            </p>
            <p>
                Animal research also tends to be helpful because of its ease of application compared to human studies. Animals can be contained and
                controlled. Researchers have many more options to manipulating diets and environments and exposures to animals. Their diets can be
                manipulated whereby humans’ dietary programs prove problematic, cost more money, and are less reliable. Animals generally have shorter
                lifespans and so implications of dietary and lifestyle changes as well as drug introductions can be monitored over multiple
                generations. In a few short years, you can see the effective results of many generations of laboratory rats, etc. In addition,
                postmortem investigation and research such as autopsies become very easy to do with animals and very difficult with humans. Animal
                remains are handled very differently from human remains. Of course we are not advocating for cruelty of animals. Animal research is
                still regulated and must be monitored, but the benefits to human health of having access to animal studies is incalculable.
            </p>
            <p>
                By applying a hundred years of animal research, we found that certain nutritional elements were essential for overall daily health.
                Because of government regulation, much of the food we consume today is fortified with various vitamins and minerals. Unfortunately,
                the quantities and number of essential nutrients given to animals is still much higher than humans. It is interesting to note that the
                average dog and cat food contains 40-50 minerals whereas the average human infant formula contains less than 12. What conclusions can
                be drawn from this. It can’t be that dogs and cats are more complex creatures than humans. It can’t be that their bodies require more
                nutrition than ours. So, the only conclusion would be that nutritional research for humans has fallen drastically behind the research
                accepted and proven in veterinary science. So, I’ve often told people, “If I get in a car accident – don’t take me to a veterinarian’s
                office to get sewn up – take me to the hospital, but if I am suffering from a degenerative, chronic illness – I definitely want to
                hear what the veterinarian has to say before I follow blindly the words of an MD.” Veterinary science has more experience and research
                to support the bodies’ own abilities to prevent and treat chronic disease. Our human medical system is astounding with its capacities
                in surgery and life-saving treatments, but the incentives are just not there to support extended research on overall health and
                nutrition and chronic disease prevention. More typically with human medicine, we go on with our lives and only look to the medical
                industry to help us after something breaks down or goes wrong. Prevention is not the MD’s priority, it is treatment. The agricultural
                veterinarian is the opposite. They make little to no money in treatment, but rather almost all their expertise is in disease
                prevention.
            </p>
            <p>
                <h5>Our Success</h5> in the nutritional world has come by emphasis on natural remedies. The belief is that much or all we need to maintain
                health is available to us on this planet if we can only discover it and identify how best to implement these natural compounds. Much
                of this thinking parallels the ancient ayurvedic medicine which used natural roots, herbs, plant medicines to treat illnesses. The
                idea is to use plants and fruits, etc. to support the human body’s own healing powers and abilities. This of course includes nutrition
                as discussed above, but to also find medicinal treatments provided by nature instead of chemicals. Vast amounts of chemicals have been
                introduced to our societies. Cleaning products, pollution, food additives, herbicides, pesticides, etc. cause increased stress and
                inflammation to our bodies. These bombardments lead to chronic inflammation and then the continued function of our bodies in these
                inflamed states cause increased deterioration and breakdown of bodily tissues. Natural extracts and plant remedies target the
                detoxification and reduction of inflammation to help prevent these destructive conditions from persisting. Many of these natural
                compounds have been used by natural healers for thousands of years. Others are more recent discoveries that we are still researching
                today to find out all the benefits they provide.
            </p>
            <p>
                A combined effort and focus seem to be the best strategy. Combining essential nutrient supplementation with natural healing botanicals
                has brought enormous benefit to people’s health. These formulas increase overall quality of life and allow for a much more active
                lifestyle with reduced suffering and discomfort. Our experience is that by following these basic protocols, a person can expect to add
                many healthful and quality years to their lives and avoid many painful and difficult health issues suffered by many others around
                them. We bring these formulas to you at prices that have never been offered. TopshelfNutra is the first to combine all these
                high-quality nutritional discoveries into simple formulas at ultra-low prices. We hope you and your family, and your friends see huge
                benefit.
            </p>
        </Container>
    );
};
