import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { useNavigate } from "react-router-dom";

export const socialLinks = [
    {
        icon: faFacebook,
        href: "https://www.facebook.com/people/Topshelfnutra/61569914567958/"
    },
    {
        icon: faYoutube,
        href: "https://www.youtube.com/@topshelfnutra1/videos"
    },
    {
        icon: faInstagram,
        href: "https://www.instagram.com/topshelfnutra/"
    }
];

type SplitSectionBannerProps = {
    groupMembership: boolean;
}

export default function SplitSectionBanner({ groupMembership }: SplitSectionBannerProps) {
  const navigate = useNavigate();
    return (
        <div className="bg-white">
            <section className="newsletter border-top-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center px-lg-5 d-flex align-items-center justify-content-center">
                            <div className="pt-4 pb-4">
                                <h5 className="mb-3 fs-3">Follow Us</h5>

                                <ul className="social-links list-unstyled d-flex flex-wrap mb-0 align-items-center justify-content-center">
                                    {socialLinks.map((link, index) => (
                                        <li key={index}>
                                            <a
                                                target="_blank"
                                                href={link.href}
                                                className="footer__social-link d-block "
                                            >
                                                <FontAwesomeIcon className="mx-2 fs-2 nav-link" icon={link.icon} />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center px-lg-5 border-left-0 border-left-lg-1 border-top-1 border-top-lg-0">
                            <div className="pt-3 pb-3 pt-xl-5 pb-xl-5"></div>
                            <div className="block-newsletter">
                                <h3 className="block__title">Refer your Friends</h3>
                                <p>
                                    TopShelfNutra is a family. We encourage and reward sharing the savings and benefits of our products and program
                                    with others. Each time you refer a friend who subscribes, both you and your friend will receive product discount
                                    credits deposited into your accounts. These credits can be applied to any future product purchase. Refer more and
                                    save more!
                                </p>
                                <BootstrapButton
                                    onClick={() => navigate("/account/signup/")}
                                    className="btn-lg"
                                    title={groupMembership ? "Become a Member for FREE" : "Become a Member for only $14.95 monthly"}
                                    variant="secondary"
                                />
                            </div>
                            <div className="pt-3 pb-3 pt-xl-5 pb-xl-5"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
