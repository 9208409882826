import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "./bootstrap-input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface BootstrapInputProps {
    value: string | boolean;
    max?: number;
    min?: number;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    onBlur?: React.FocusEventHandler<any> | undefined;
    onKeyDown?: any;
    setValue?: any;
    label?: string;
    placeholder?: string;
    className?: string;
    type?: "email" | "password" | "text" | "checkbox" | "number" | "phone";
    valid?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;
}

const getFormattedInputValue = (value: string): string => {
    const digits = value.replace(/\D/g, "").slice(0, 10);
    let res = "";
    if (digits.length > 0) {
        res = `${digits.slice(0, 3)}`;
    }
    if (digits.length >= 4) {
        res = `(${res})${digits.slice(3, 6)}`;
    }
    if (digits.length >= 7) {
        res += `-${digits.slice(6)}`;
    }
    return res;
};

export const BootstrapInput = ({
    label = "",
    placeholder,
    value,
    onChange,
    onBlur,
    onKeyDown,
    className,
    max,
    min,
    type = "text",
    valid = true,
    autoFocus = false,
    readOnly = false,
}: BootstrapInputProps) => {
    const [changedType, setChangedType] = useState(type === "password");
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (type === "phone" && typeof value === "string") {
            setInputValue(value);
        }
    }, []);

    const handleSetInputvalue = (event: any) => {
        const { value, selectionStart, selectionEnd } = event.target;
        const formattedInputValue = getFormattedInputValue(value);
        setInputValue(formattedInputValue);
        event.target.value = formattedInputValue;
        onChange && onChange(event);
        setTimeout(() => {
            if (inputRef.current) {
                const position = formattedInputValue.indexOf(value[selectionStart - 1], selectionEnd - 1) + 1 || selectionEnd;
                inputRef.current.setSelectionRange(position, position);
            }
        }, 0);
    };

    return (
        <>
            {type !== "checkbox" ? (
                <Form className="bootstrap-input">
                    <Form.Group className={`${className ? className : ""}`}>
                        {label.length > 0 && <Form.Label className="bootstrap-input__label">{label}</Form.Label>}
                        <div className="position-relative">
                            <Form.Control
                                max={max}
                                min={min}
                                ref={inputRef}
                                className={`${valid ? "bootstrap-input__valid-field shadow-none" : "bootstrap-input__invalid-field shadow-none"}`}
                                value={type === "phone" ? inputValue : (value as string)}
                                onChange={type === "phone" ? (e) => handleSetInputvalue(e) : onChange}
                                onKeyDown={onKeyDown ? onKeyDown : undefined}
                                type={changedType ? "password" : (type === "password" && changedType === false) || type === "phone" ? "text" : type}
                                placeholder={placeholder ? placeholder : ""}
                                autoFocus={autoFocus}
                                readOnly={readOnly}
                                onBlur={onBlur}
                            />
                            {changedType && (
                                <FontAwesomeIcon onClick={() => setChangedType(false)} className="bootstrap-input__toggle" icon={faEye} />
                            )}
                            {!changedType && type === "password" && (
                                <FontAwesomeIcon
                                    onClick={() => setChangedType(true)}
                                    className="bootstrap-input__toggle bootstrap-input__green"
                                    icon={faEye}
                                />
                            )}
                        </div>
                    </Form.Group>
                </Form>
            ) : (
                <Form.Check
                    defaultChecked={value as boolean}
                    onChange={onChange}
                    className="shadow-none bootstrap-input__label bootstrap-input__checkbox"
                    type={type}
                    label={label}
                />
            )}
        </>
    );
};
