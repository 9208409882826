import React from "react";
import { Container } from "react-bootstrap";

interface OurExpertsPageProps {}

export const OurExpertsPage = ({}: OurExpertsPageProps) => {
    return (
        <Container style={{ minHeight: "calc(100vh - 220px)"}} className="my-4">
            <h1 className="section-title text-uppercase fw-bold my-5">Meet Our Experts</h1>
            <p>
                Our team of experts include people from many walks of life and professions. We have doctors, nurses, nutritional experts, fitness
                experts, homemakers, coaches, counselors, plumbers, dentists, and every other profession you can name. How do we determine whether
                someone is an expert? Well, are they expert at what they do? Do they contribute to others in a way that improves the quality of
                people’s lives around them. Chances are, you are also an expert, and we want to highlight you and your contributions. We will
                continually add to our list of experts and invite them to be guests on our various webinars and calls. Browse through our valued list
                below of TopShelfNutra’s many experts. Find a couple of them that seem to relate to you. Listen to them. Learn about them. Find out
                how they benefit from and contribute to the TopShelfNutra family and then commit yourself to become an expert as well and be a
                blessing to others in your path!
            </p>
        </Container>
    );
};
