import { Col, Container, Row } from "react-bootstrap";
import { BootstrapInput } from "../../../components/BootstrapInput/BootstrapInput";
import { Member, MemberApi } from "../../../apis/MemberApi";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { toast } from "react-toastify";

interface DetailsProps {
    accountDetailsForm: Member;
    setAccountDetailsForm: React.Dispatch<React.SetStateAction<Member | undefined>>;
    updatePatchingProps: (property: string, value: any, remove?: boolean) => void;
    setPatchingProps: React.Dispatch<
        React.SetStateAction<
            {
                prop: string;
                value: string;
            }[]
        >
    >;
    patchingProps: {
        prop: string;
        value: string;
    }[];
}

export const Details = ({ accountDetailsForm, setAccountDetailsForm, updatePatchingProps, setPatchingProps, patchingProps }: DetailsProps) => {
    const { authMember, updateAuthMember } = useAuthenticatedUser();
    const letUsKnowReadOnly = accountDetailsForm.how_heard ? accountDetailsForm.how_heard.length > 0 : false;

    const patchMemberDetailsClick = async () => {
        if (accountDetailsForm && accountDetailsForm.phone_number && accountDetailsForm.phone_number.length > 0) {
            let obj: any = {};
            for (let i = 0; i < patchingProps.length; i++) {
                let patchedProp = patchingProps[i];
                obj[patchedProp.prop] = patchedProp.value;
            }
            let response = await MemberApi.PatchOneMember(accountDetailsForm.member_id, obj);

            if (response.success && authMember) {
                updateAuthMember({ ...accountDetailsForm, active: authMember?.active ? authMember?.active : false, default_payment_method: authMember.default_payment_method });
                setPatchingProps([]);
                toast("Successfully updated your account.", { type: "success"});
            } else {
                toast(response.message, { type: "warning" });
            }
        } else {
            toast(`Looks like we don't have your phone number, please add your phone number to update your other details.`, { type: "warning"});
        }
    };

    return (
        <Container fluid className="mb-5 wrapper-tab d-block stage-container">
            <Row className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 py-6 wrapper-details">
                <span className="fs-4 mb-4 fw-bold">Details</span>
                <Row>
                    <Col className="col-12" sm={12} md={5} lg={5}>
                        <BootstrapInput
                            readOnly={letUsKnowReadOnly}
                            label="Let us know who referred you"
                            value={accountDetailsForm.how_heard}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, how_heard: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("how_heard", e.target.value, e.target.value === authMember.how_heard);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-12" sm={12} md={5}>
                        <BootstrapInput
                            label="First name"
                            value={accountDetailsForm.first_name}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, first_name: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("first_name", e.target.value, e.target.value === authMember.first_name);
                                }
                            }}
                        />
                    </Col>
                    <Col className="col-12" sm={12} md={5}>
                        <BootstrapInput
                            label="Last name"
                            value={accountDetailsForm.last_name}
                            onChange={(e) => {
                                setAccountDetailsForm({ ...accountDetailsForm, last_name: e.target.value });
                                if (authMember) {
                                    updatePatchingProps("last_name", e.target.value, e.target.value === authMember.last_name);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Phone number"
                            value={accountDetailsForm.phone_number ?? ""}
                            type="phone"
                            onChange={(e) => {
                                setAccountDetailsForm({
                                    ...accountDetailsForm,
                                    phone_number: e.target.value,
                                });
                                if (authMember) {
                                    updatePatchingProps(
                                        "phone_number",
                                        e.target.value === "" ? null : e.target.value,
                                        (e.target.value === "" ? null : e.target.value) === authMember.phone_number
                                    );
                                }
                            }}
                        />
                    </Col>
                    <Col className="mt-sm-3 mt-md-0 mt-3" sm={12} md={5}>
                        <BootstrapInput
                            label="Text Messages"
                            value={accountDetailsForm.allow_text_messages}
                            type="checkbox"
                            onChange={(e) => {
                                setAccountDetailsForm({
                                    ...accountDetailsForm,
                                    allow_text_messages: !accountDetailsForm.allow_text_messages,
                                });
                                if (authMember) {
                                    updatePatchingProps(
                                        "allow_text_messages",
                                        !accountDetailsForm.allow_text_messages,
                                        !accountDetailsForm.allow_text_messages === authMember.allow_text_messages
                                    );
                                }
                            }}
                        />
                        <label className="form-check-label  mt-2">I agree to receive text messages from TopShelfNutra</label>
                        <div className="fs-6 mt-2">
                            Note - By checking this checkbox, you opt-in to receive messages from TopShelfNutra, including 2FA codes and password reset messages etc. Message and data rates may apply.
                        </div>
                    </Col>
                </Row>

                <div className="d-flex py-4 w-50">
                    <div className="mb-3">
                        <BootstrapButton
                            disabled={patchingProps.length === 0}
                            onClick={patchMemberDetailsClick}
                            variant="secondary"
                            title="Update Details"
                        />
                    </div>
                </div>
            </Row>

        </Container>
    );
};
