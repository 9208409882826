import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { ShoppingCartOffCanvas } from "../components/ShoppingCartOffCanvas/ShoppingCartOffCanvas";
import { useAuthenticatedUser } from "./AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Product, ProductApi } from "../apis/ProductApi";

export interface CartItem {
    id: string;
    quantity: number;
    price: string;
    single: boolean;
}

interface ShoppingCartProviderProps {
    children: ReactNode;
}

interface ShoppingCartContext {
    openCart: () => void;
    closeCart: () => void;
    clearCart: () => void;
    getItemQuantity: (id: string) => number;
    increaseCartQuantity: (product: Product) => void;
    decreaseCartQuantity: (id: string) => void;
    removeFromCartAuth: (id: string, single: boolean) => void;
    removeFromCart: (id: string) => void;
    cartQuantity: number;
    cartItems: CartItem[];
    setCartItems: React.Dispatch<React.SetStateAction<CartItem[]>>;
    allProducts: Product[];
}

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export const useShoppingCart = () => {
    return useContext(ShoppingCartContext);
};

export const ShoppingCartProvider = ({ children }: ShoppingCartProviderProps) => {
    const [shoppingCartOffCanvasShow, setShoppingCartOffCanvasShow] = useState(false);
    const [cartItems, setCartItems] = useLocalStorage<CartItem[]>("shopping-cart", []);
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const { authMember } = useAuthenticatedUser();

    useEffect(() => {
        ProductApi.GetAll().then(setAllProducts).catch(console.error);
    }, []);

    const getItemQuantity = (id: string) => {
        let quantity = 0;
        cartItems.forEach((item) => {
            if (item.id === id) {
                quantity += item.quantity;
            }
        });
        return quantity;
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const increaseCartQuantity = (product: Product) => {
        if (authMember && authMember.active) {
            let currentCartItems = [...cartItems];
            let itemAlreadyExistsSingle = cartItems.findIndex((item) => item.id === product.id && item.single);
            let itemAlreadyExistsMultiple = cartItems.findIndex((item) => item.id === product.id && !item.single);
            if (itemAlreadyExistsSingle === -1) {
                setCartItems([...currentCartItems, { id: product.id, price: product.member_single_price.id, single: true, quantity: 1 }]);
            } else if (itemAlreadyExistsSingle !== -1 && itemAlreadyExistsMultiple === -1) {
                setCartItems([...currentCartItems, { id: product.id, price: product.member_multiple_price.id, single: false, quantity: 1 }]);
            } else {
                let copyOfCurrentCartItems = [...currentCartItems];
                if (copyOfCurrentCartItems[itemAlreadyExistsMultiple].quantity < 10) {
                    copyOfCurrentCartItems[itemAlreadyExistsMultiple].quantity += 1;
                }
                setCartItems(copyOfCurrentCartItems);
            }
        } else {
            let currentCartItems = [...cartItems];
            //Testing mode
            if(authMember && (product.id === "prod_RTjv4nDsDNVaED" || product.id === "prod_RTjwQAF9EDUygw") && !currentCartItems.find((item) => item.id === "prod_QMplKEph6gdyDi")) {
                currentCartItems.push({ id: "prod_QMplKEph6gdyDi", price: "price_1PW65uIrieNUMmEn8KrRL6JD", single: false, quantity: 1 })
            }
            // Production mode
            if(authMember && (product.id === "prod_RTkr7AJWSgqKeR" || product.id === "prod_RTkrAmOgfRMrlv") && !currentCartItems.find((item) => item.id === "prod_RTkuio8RiPFF0D")) {
                currentCartItems.push({ id: "prod_RTkuio8RiPFF0D", price: "price_1QanOfIrieNUMmEnX8OKVXPm", single: false, quantity: 1 })
            }
           
            let itemAlreadyExists = cartItems.findIndex((item) => item.id === product.id);
            if (itemAlreadyExists === -1) {
                setCartItems([...currentCartItems, { id: product.id, price: product.retail_price.id, single: false, quantity: 1 }]);
            } else {
                let copyOfCurrentCartItems = [...currentCartItems];
                if (copyOfCurrentCartItems[itemAlreadyExists].quantity < 10) {
                    copyOfCurrentCartItems[itemAlreadyExists].quantity += 1;
                }
                setCartItems(copyOfCurrentCartItems);
            }
        }
    };

    const decreaseCartQuantity = (id: string) => {
        if (authMember && authMember.active) {
        } else {
            setCartItems((currItems) => {
                let deletingItem = cartItems.find((item) => item.id === id);
                if (deletingItem?.quantity === 1) {
                    //First two are testing mode ones
                    if(deletingItem.id === "prod_RTjwQAF9EDUygw" || deletingItem.id === "prod_RTjv4nDsDNVaED" || deletingItem.id === "prod_RTkrAmOgfRMrlv" || deletingItem.id === "prod_RTkr7AJWSgqKeR") {
                       currItems = currItems.filter((item) => item.id !== "prod_RTkuio8RiPFF0D" && item.id !== "prod_QMplKEph6gdyDi")
                    }
                    return currItems.filter((item) => item.id !== id);
                } else {
                    return currItems.map((item) => {
                        if (item.id === id) {
                            return { ...item, quantity: item.quantity - 1 };
                        } else {
                            return item;
                        }
                    });
                }
            });
        }
    };

    const removeFromCartAuth = (id: string, single: boolean) => {
        let currentCartItems = [...cartItems];
        if (single) {
            currentCartItems = currentCartItems.filter((item) => item.id !== id);
        } else {
            const itemAlreadyExistsMultiple = currentCartItems.findIndex((item) => item.id === id && !item.single);
            if (itemAlreadyExistsMultiple !== -1) {
                currentCartItems.splice(itemAlreadyExistsMultiple, 1);
            }
        }
        setCartItems(currentCartItems);
    };

    const removeFromCart = (id: string) => {
        setCartItems((currItems) => {
            // First two are testing mode ones.
            if(id === "prod_RTjwQAF9EDUygw" || id === "prod_RTjv4nDsDNVaED" || id === "prod_RTkrAmOgfRMrlv" || id === "prod_RTkr7AJWSgqKeR") {
                currItems = currItems.filter((item) => item.id !== "prod_RTkuio8RiPFF0D" && item.id !== "prod_QMplKEph6gdyDi");
            }
            return currItems.filter((item) => item.id !== id);
        });
    };

    const openCart = () => setShoppingCartOffCanvasShow(true);
    const closeCart = () => setShoppingCartOffCanvasShow(false);

    const cartQuantity = cartItems.reduce((quantity, item) => item.quantity + quantity, 0);

    return (
        <ShoppingCartContext.Provider
            value={{
                clearCart,
                openCart,
                closeCart,
                cartQuantity,
                cartItems,
                setCartItems,
                getItemQuantity,
                increaseCartQuantity,
                decreaseCartQuantity,
                removeFromCart,
                removeFromCartAuth,
                allProducts,
            }}
        >
            {children}
            <ShoppingCartOffCanvas shoppingCartOffCanvasShow={shoppingCartOffCanvasShow} />
        </ShoppingCartContext.Provider>
    );
};

export const recalculateCartItems = (
    isActiveMember: boolean,
    currentCartItems: CartItem[],
    setCartItems: React.Dispatch<React.SetStateAction<CartItem[]>>,
    allProducts: Product[],
    setCartItemsOrReturn: "SetCartItems" | "Return" = "SetCartItems"
) => {
    let newCartItems: CartItem[] = [];
    if (isActiveMember) {
        for (let i = 0; i < currentCartItems.length; i++) {
            let cartItem = currentCartItems[i];
            let cartItemProduct = allProducts.find((product) => product.id === cartItem.id);
            if (cartItem.quantity > 1 && cartItemProduct) {
                newCartItems = [
                    ...newCartItems,
                    { id: cartItem.id, price: cartItemProduct.member_single_price.id, single: true, quantity: 1 },
                    { id: cartItem.id, price: cartItemProduct.member_multiple_price.id, single: false, quantity: cartItem.quantity - 1 },
                ];
            }
        }
    } else {
        for (let i = 0; i < currentCartItems.length; i++) {
            let cartItem = currentCartItems[i];
            let cartItemExists = newCartItems.findIndex((currentCartItem) => currentCartItem.id === cartItem.id);
            let cartItemProduct = allProducts.find((product) => product.id === cartItem.id);

            if (cartItemExists === -1 && cartItemProduct) {
                newCartItems = [
                    ...newCartItems,
                    { id: cartItem.id, price: cartItemProduct.retail_price.id, single: false, quantity: cartItem.quantity },
                ];
            } else {
                newCartItems[cartItemExists].quantity = newCartItems[cartItemExists].quantity + cartItem.quantity;
            }
        }
    }

    if (setCartItemsOrReturn === "Return") {
        return newCartItems;
    } else {
        setCartItems(newCartItems);
    }
};
