import React from "react";
import { Container } from "react-bootstrap";

interface PhilPageProps {}

export const PhilPage = ({}: PhilPageProps) => {
    return (
        <Container className="my-4">
            <h1 className="section-title text-uppercase fw-bold my-5">Philosophy of Products</h1>
            <p>
                TopShelfNutra’s product philosophy is based upon contributions from many disciplines. Some of the background is also shared under the
                tab, “Our Story.” Foundationally, we believe that the body has an innate, though limited, ability to maintain, repair, and rebuild
                itself. Our bodies are amazing creations with a desire to not only survive, but even to thrive. What this means, is that we will fight
                for survival, but when survival is ensured, our bodies don’t quit fighting, but rather, continue to repair and rebuild tissues to
                return to its prior healthy state and to live optimally. If necessities are met, the body will continue to work on improvement where
                possible.
            </p>
            <p>
                Our formulas include both the “Essential Nutrients” as well as many “Beneficial Nutrients.” Essential Nutrients are those nutrients
                that must be present for essential functions to happen in the body, and which nutrients cannot be produced by the body and must
                therefore be consumed. Deficiencies of these nutrients over time will lead to break downs in body processes and over time lead to
                chronic illness. According to experts, there are at least 90 Essential Nutrients identified for humans. These include at least 60
                minerals, 16 vitamins, 12 amino acids and 2 essential fatty acids. The exact number of “Essential Nutrients” is under dispute and
                discussion, but experts are at least in agreement that there are 90 or more. Humans gain many of these nutrients by consuming plants
                and animal tissues. Minerals makeup more than two thirds of the essential nutrients, but unlike vitamins, amino acids, and fatty acids
                they cannot be produced by plants or animals. Both plants and animals are dependent upon the soil to provide these life essential
                minerals. Traditionally, this has not been a big deal since people largely grew their own produce and crop rotations and natural
                fertilizers and flood irrigation helped replenish the mineral content in the farm soils. In the last century, we have observed our
                farm soils becoming depleted of these life supporting minerals as we have commercialized our farming methods and turned to more
                efficient chemical fertilizers (NPK) loaded with only 3 minerals (nitrogen, phosphorous, and potassium). These chemical fertilizers
                maximize the yield and production of the farm soils, but do not replace the missing essential minerals – especially the trace element
                minerals which according to the USDA’s declaration in 1920 that America’s farm soils are desperately deficient in vital trace minerals
                and suggests fortifying all food containing produce grown on them.
            </p>
            <p>
                Another beneficial development for farmers regarding production yields and efficiencies that is affecting human health is the
                introduction of genetically modified seeds resistant to various herbicides and pesticides. The use of modified seeds in combination
                with these chemicals has provided large production of food items that are more difficult and inflammatory to human digestive systems.
                Both the chemicals and genetically altered produce have been linked to many progressive health issues and concerns. Because of the
                efficiencies, much of today’s produce contains genetic alterations as well as higher chemical loads. People consuming this produce are
                needing increasing levels of detoxifying nutrients and anti-inflammatory compounds to counter these changes. Many clinical studies
                today suggest that it is becoming more and more difficult to
            </p>
        </Container>
    );
};
