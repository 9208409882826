import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { Col, Row } from "react-bootstrap";
import { Member } from "../../apis/MemberApi";
import { BootstrapButton } from "../BootstrapButton/BootstrapButton";
import { useState } from "react";
import { BootstrapDropdown, MenuItem } from "../BootstrapDropdown/BootstrapDropdown";
import { BootstrapInput } from "../BootstrapInput/BootstrapInput";
import { useNavigate } from "react-router-dom";
import Stripe from "stripe";

interface PaymentMethodProps {
    method: Stripe.PaymentMethod;
    authMember: Member;
    handleRemovePaymentMethod: (customer_id: string, card_id: string) => Promise<void>;
    handleSetDefaultPaymentMethod: (customer_id: string, card_id: string) => Promise<void>;
    handleUpdatePaymentMethod: (card_id: string, exp_month: string, exp_year: string, postal_code: string) => Promise<void>;
}

export const months: MenuItem[] = [
    { title: "January", id: 1 },
    { title: "Febuary", id: 2 },
    { title: "March", id: 3 },
    { title: "April", id: 4 },
    { title: "May", id: 5 },
    { title: "June", id: 6 },
    { title: "July", id: 7 },
    { title: "August", id: 8 },
    { title: "September", id: 9 },
    { title: "October", id: 10 },
    { title: "November", id: 11 },
    { title: "December", id: 12 },
];

export const PaymentMethod = ({
    method,
    authMember,
    handleRemovePaymentMethod,
    handleSetDefaultPaymentMethod,
    handleUpdatePaymentMethod,
}: PaymentMethodProps) => {
    const [inEditMode, setInEditMode] = useState(false);
    const [month, setMonth] = useState({ value: "Choose One", id: 0 });
    const [year, setYear] = useState({ value: "Choose One", id: 0 });
    const [zipCode, setZipCode] = useState("");
    const navigate = useNavigate();

    const handleMonthPick = (monthValue: number) => {
        let findMonth = months.find((month) => month.id === monthValue);
        if (findMonth && findMonth.id) {
            setMonth({ id: findMonth.id, value: findMonth.title });
        }
    };

    const handleYearPick = (yearValue: number) => {
        let dropdownItems = getYearsDropdownItems();
        let findYear = dropdownItems.find((year) => year.id === yearValue);
        if (findYear && findYear.id) {
            setYear({ id: findYear.id, value: findYear.title });
        }
    };

    const getYearsDropdownItems = () => {
        let currentYear = new Date().getFullYear();
        let dropdownItems = [{ title: currentYear.toString(), id: currentYear }];
        for (let i = 0; i < 8; i++) {
            let year = new Date().getFullYear() + i + 1;
            dropdownItems.push({ title: year.toString(), id: year });
        }
        return dropdownItems;
    };

    return (
        <Col xl={12} key={method.id} className="my-2 p-3 tsn-bg-light-gray border-radius d-flex justify-content-between">
            <Row className="w-100">
                {method.card && (
                    <Col md={6} className="d-flex col-12">
                        <img style={{ height: "50px" }} src={`/${method.card.brand}.svg`} alt={method.card.brand} />
                        <div className="ms-4">
                            <h5 className="mb-0 h6 h6">**** {method.card.last4}</h5>
                            <p className="mb-0 small">
                                Expires {method.card.exp_month}/{method.card.exp_year}
                            </p>
                        </div>
                    </Col>
                )}
                <Col md={6} className="mt-4 mt-md-0 col-12 d-flex justify-content-start justify-content-md-end">
                    {authMember.default_payment_method !== method.id ? (
                        <BootstrapButton
                            onClick={() => handleSetDefaultPaymentMethod(authMember.customer_id, method.id)}
                            className="mt-2"
                            title="Set as Default"
                        />
                    ) : (
                        <div className="mt-2 d-flex align-items-center">
                            <b>Default</b>
                        </div>
                    )}
                </Col>

                {inEditMode && (
                    <Col className="my-2 col-12">
                        <Row>
                            <Col md={6} lg={3} className="my-2 col-12">
                                <BootstrapDropdown value={month.value} onClick={handleMonthPick} label="Month" dropdownItems={months} />
                            </Col>
                            <Col md={6} lg={3} className="my-2 col-12">
                                <BootstrapDropdown value={year.value} onClick={handleYearPick} label="Year" dropdownItems={getYearsDropdownItems()} />
                            </Col>
                            <Col md={6} lg={3} className="my-2 col-12">
                                <BootstrapInput
                                    label="Zip Code"
                                    value={zipCode}
                                    onChange={(e) => {
                                        if (e.target.value.length < 6) {
                                            setZipCode(e.target.value);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col className="mt-4 mt-md-1 col-12 d-flex align-items-center">
                    <BootstrapButton
                        onClick={() => {
                            setYear({ value: "Choose One", id: 0 });
                            setMonth({ value: "Choose One", id: 0 });
                            setZipCode("");
                            setInEditMode(!inEditMode);
                        }}
                        variant="secondary"
                        className="me-3"
                        title={inEditMode ? "Cancel" : "Update"}
                    />

                    {(authMember.default_payment_method !== method.id || !authMember.active) && !inEditMode && (
                        <BootstrapButton
                            onClick={() => {
                                handleRemovePaymentMethod(authMember.customer_id, method.id);
                                navigate('/account');
                            }}
                            variant="danger"
                            className="me-3"
                            title="Remove"
                        />
                    )}
                    {inEditMode && (
                        <BootstrapButton
                            onClick={() => {
                                handleUpdatePaymentMethod(method.id, month.id.toString(), year.id.toString(), zipCode);
                                setInEditMode(false);
                                navigate("/account");
                            }}
                            variant="primary"
                            className="me-3"
                            disabled={month.id === 0 || year.id === 0 || zipCode.length > 5 || zipCode.length < 5}
                            title="Save Changes"
                        />
                    )}
                </Col>
            </Row>
        </Col>
    );
};
