import { Link, useParams } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useAuthenticatedUser } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import "./product-details-page.scss";
import { MembershipInfoOffCanvas } from "../../components/MembershipInfoOffCanvas/MembershipInfoOffCanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ProductDetailsSlider from "../../components/ProductDetailsSlider/ProductDetailsSlider";
import ShareComponent from "../../components/ShareComponent/ShareComponent";
import { Loader } from "../../components/Loader/Loader";
import { truncateString } from "../../utils/function";
import { Accordion } from "../../components/Accordion/Accordion";

interface ProductDetailsPageProps {}

export const ProductDetailsPage = ({}: ProductDetailsPageProps) => {
    const searchParams = useParams();
    const { allProducts, getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();
    const { authMember, isLoggedIn } = useAuthenticatedUser();
    const [photoIndex, setPhotoIndex] = useState(0);
    const [labelImageIsValid, setLabelImageIsValid] = useState(true);
    const [ingredientImageIsValid, setIngredientImageIsValid] = useState(true);
    const [supportsImageIsValid, setSupportsImageIsValid] = useState(true);
    const [membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow] = useState(false);

    const product = allProducts.find((product) => product.id === searchParams.id);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (product) {
        const quantity = getItemQuantity(product.id);

        return (
            <>
                <section className="product-single container mb-5">
                    <div className="row">
                        <div className="d-flex justify-content-between pb-md-2 mt-3">
                            <div className="breadcrumb mb-0 flex-grow-1">
                                <div className="d-flex">
                                    <Link to="/products/" className="fs-6 text-primary fw-bold btn-catalog">
                                        PRODUCTS
                                    </Link>
                                    <div className="ms-2 fs-6"> / {product.name.toUpperCase()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <ProductDetailsSlider content={product.content} />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="product-single__name mb-5">
                                    {product.name}
                            </h1>
                            {(product.id === "prod_RTjwQAF9EDUygw" || product.id === "prod_RTjv4nDsDNVaED" || product.id === "prod_RTkrAmOgfRMrlv" || product.id === "prod_RTkr7AJWSgqKeR") && (
                                <ul>
                                    <li>BulaFit Fitness Program</li>
                                    {(!authMember || !authMember.active) && <li>1 60 Day Complimentary TSN Membership</li>}
                                    <li>1 BulaFit Shaker Bottle</li>
                                    <li>Resistance Workout Bands</li>
                                    <li>On-the-go Container</li>
                                    <li>Mindset 60 Program Enrollment</li>
                                    <li>1 GTM Heirloom Botanicals</li>
                                    <li>1 ATP Balance</li>
                                    <li>1 Detox Tea</li>
                                    <li>2 Vital Fuel Shakes</li>
                                    <li>{(product.id === "prod_RTjwQAF9EDUygw" || product.id === "prod_RTkrAmOgfRMrlv") ? "2 Sport N Go" : "1 Sport N Go"}</li>
                                    {(product.id === "prod_RTjv4nDsDNVaED" || product.id === "prod_RTkr7AJWSgqKeR") && <li>1 Java Boost Coffee</li>}
                                </ul>
                            )}
                            {product.id === "prod_RWND0mRoOot4y8" && (
                                <ul>
                                    <li>BulaFit Fitness Program</li>
                                    <li>Mindset 60 Program Enrollment</li>
                                    <li>Recognition and Awards</li>
                                </ul>
                            )}
                            <div className="mt-4">
                                <span className="fs-5 fw-bold">Retail:</span>
                                <span className="fs-5 ms-2 text-primary">{product.retail_price.unit_amount_formatted}</span>
                            </div>
                            <div className="">
                                <div className="d-flex fs-5 align-self-center">
                                    <div className="fw-bold">Member:</div>
                                    <div className="d-flex flex-column ms-2">
                                        <div className="fs-5">
                                            First:<span className="ms-1 text-primary">{product.member_single_price.unit_amount_formatted}</span>
                                            <FontAwesomeIcon
                                                onClick={() => setMembershipInfoOffCanvasShow(true)}
                                                className="mx-2 fs-5 product-item__info"
                                                icon={faInfoCircle}
                                            />
                                        </div>
                                        <div className="fs-5 mb-2">
                                            Each Additional:{" "}
                                            <span className="ms-1  text-primary">{product.member_multiple_price.unit_amount_formatted}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {product.description.length !== 5 && <div className="my-5 product-single__short-desc">
                                <div dangerouslySetInnerHTML={{ __html: product.description?.split("Key Benefits")[0] as any }} />
                            </div>}
                            <form onSubmit={(e) => e.preventDefault()}>
                                {quantity === 0 ? (
                                    <>
                                        {product.is_coming_soon && (
                                            <div className="product-item__out_of p-2 px-3 d-flex justify-content-center">Coming Soon</div>
                                        )}
                                        {!product.is_coming_soon && product.is_back_ordered && (
                                            <div className="product-item__out_of p-2 px-3 d-flex justify-content-center">Out of Stock</div>
                                        )}
                                        {!product.is_back_ordered && !product.is_coming_soon && (
                                            <div className="product-single__addtocart">
                                                <div className="qty-control position-relative">
                                                    <BootstrapButton
                                                        className={`btn-addtocart js-open-aside`}
                                                        onClick={() => (quantity > 0 ? removeFromCart(product.id) : increaseCartQuantity(product))}
                                                        title={quantity > 0 ? "Remove" : "Add to Cart"}
                                                        variant={quantity > 0 ? "outline-danger" : "secondary"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="product-single__addtocart">
                                        <div className="qty-control position-relative">
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={quantity}
                                                min="1"
                                                disabled
                                                className="qty-control__number text-center"
                                            />
                                            <div onClick={() => decreaseCartQuantity(product.id)} className="qty-control__reduce">
                                                -
                                            </div>
                                            <div onClick={() => increaseCartQuantity(product)} className="qty-control__increase">
                                                +
                                            </div>
                                        </div>
                                        <BootstrapButton
                                            className={`btn-addtocart js-open-aside`}
                                            onClick={() => (quantity > 0 ? removeFromCart(product.id) : increaseCartQuantity(product))}
                                            title={quantity > 0 ? "Remove" : "Add to Cart"}
                                            variant={quantity > 0 ? "outline-danger" : "secondary"}
                                        />
                                    </div>
                                )}
                            </form>
                            <div className="product-single__addtolinks mt-3">
                                <ShareComponent title={product.name} />
                            </div>
                        </div>
                    </div>
                    {product.description.length !== 5 && <Accordion
                        item={{
                            id: 0,
                            heading: "Full Description",
                            body: <div dangerouslySetInnerHTML={{ __html: product.description as any }} />
                        }}
                        html={false}
                    />}
                </section>
                <MembershipInfoOffCanvas
                    membershipInfoOffCanvasShow={membershipInfoOffCanvasShow}
                    setMembershipInfoOffCanvasShow={setMembershipInfoOffCanvasShow}
                />
            </>
        );
    } else {
        return <Loader />;
    }
};
