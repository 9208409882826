import { useEffect, useState } from "react";
import { Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/thumbs";
import "swiper/css";
import "photoswipe/dist/photoswipe.css";
import tippy from "tippy.js";
import { Gallery, Item } from "react-photoswipe-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

type ProductDetailsSliderProps = {
    content: string[];
};

export default function ProductDetailsSlider({ content }: ProductDetailsSliderProps) {
    useEffect(() => {
        tippy("[data-tippy-content]");
    }, []);

    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    return (
        <div className="product-single__media vertical-thumbnail product-media-initialized">
            <div className="product-single__image position-relative">
                <Gallery>
                    <Swiper
                        modules={[Thumbs, Navigation]}
                        slidesPerView={1}
                        thumbs={{
                            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                        }}
                        onSwiper={setThumbsSwiper}
                        navigation={{ prevEl: ".ssnbp1", nextEl: ".ssnbn1" }}
                        className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
                    >
                        {content && content.length > 0 ? (
                            content.map((elm, i) => (
                                <SwiperSlide
                                    style={{
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                        position: "relative",
                                    }}
                                    key={i}
                                    className="swiper-slide product-single__image-item"
                                >
                                    <Item
                                        original={`https://res.cloudinary.com/dbltuv7gx/image/upload/${elm}`}
                                        thumbnail={`https://res.cloudinary.com/dbltuv7gx/image/upload/${elm}`}
                                        width="674"
                                        height="674"
                                    >
                                        {({ ref, open }) => (
                                            <>
                                                <img
                                                    loading="lazy"
                                                    className="h-auto w-100"
                                                    src={`https://res.cloudinary.com/dbltuv7gx/image/upload/${elm}`}
                                                    alt="image"
                                                />
                                            </>
                                        )}
                                    </Item>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide
                                style={{
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                                className="swiper-slide product-single__image-item"
                            >
                                <Item
                                    original={`https://topshelfnutra.com/main.svg`}
                                    thumbnail={`https://topshelfnutra.com/main.svg`}
                                    width="674"
                                    height="674"
                                >
                                    {({ ref, open }) => (
                                        <>
                                            <img loading="lazy" className="h-auto w-100" src={`https://topshelfnutra.com/main.svg`} alt="image" />
                                        </>
                                    )}
                                </Item>
                            </SwiperSlide>
                        )}

                        <div className="cursor-pointer swiper-button-prev ssnbp1">
                            <FontAwesomeIcon className="arrows" icon={faChevronLeft} />
                        </div>
                        <div className="cursor-pointer swiper-button-next ssnbn1">
                            <FontAwesomeIcon className="arrows" icon={faChevronRight} />
                        </div>
                    </Swiper>
                </Gallery>
            </div>
            <div className="product-single__thumbnail">
                <Swiper
                    modules={[Thumbs]}
                    breakpoints={{
                        0: {
                            direction: "horizontal",
                            slidesPerView: 4,
                        },
                        992: {
                            direction: "vertical",
                        },
                    }}
                    className="swiper-container swiper-container-initialized swiper-container-pointer-events swiper-container-free-mode swiper-container-thumbs swiper-container-horizontal"
                    onSwiper={setThumbsSwiper}
                    slidesPerView={4}
                >
                    {content.map((elm, i) => (
                        <SwiperSlide key={i} className="swiper-slide product-single__image-item" style={{ marginBottom: "10px" }}>
                            <img
                                loading="lazy"
                                className="h-auto"
                                src={`https://res.cloudinary.com/dbltuv7gx/image/upload/${elm}`}
                                width="104"
                                height="104"
                                alt="image"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}
