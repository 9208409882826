import { Col, Container, Row } from "react-bootstrap";
import "./order-history.scss";
import { useEffect, useState } from "react";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";

interface OrderHistoryProps {
    orderHistory: any[];
    count: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    offset: number;
    limit: number;
}

export interface OrderHisObj {
    display_id: string;
    ordered_on: string;
    order_id: string;
    receipt_url: string;
}

export const OrderHistory = ({ orderHistory, count, limit, setOffset, offset }: OrderHistoryProps) => {
    const [backButtonLoading, setBackButtonLoading] = useState(false);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);

    useEffect(() => {
        setBackButtonLoading(false);
        setNextButtonLoading(false);
    }, [orderHistory]);

    const getOneOrder = (receipt_url: string) => {
        if (receipt_url && receipt_url.length > 0) {
            window.open(receipt_url, "_blank");
        }
    };

    const openTrackingNumberClick = (trackingNumber: string) => {
        window.open(`https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingNumber}`, "_blank");
    };

    return (
        <Container fluid className="stage-container">
            <Row className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-5 wrapper-tab wrapper-affiliate d-block">
                <Col className="fs-4 fw-bold">Order History {count !== 0 && `(${limit})`}</Col>
                {limit < count && <Col className="fs-5 col-12">Showing Orders: ({limit})</Col>}
            </Row>
            {orderHistory.length > 0 ? (
                orderHistory.map((orderHis, parentI: number) => {
                    return (
                        <Row key={orderHis.display_id} className="my-5 p-2 border-top border-bottom">
                            <Col className="col-12 d-flex justify-content-md-start justify-content-center align-items-center">
                                <div onClick={() => getOneOrder(orderHis.receipt_url)}>
                                    <b>Order#</b>:
                                    <span className="px-2 order-history__link">
                                        TSN-{orderHis.display_id}
                                        {orderHis.order_id.slice(0, 5)}
                                    </span>
                                </div>
                            </Col>
                            {orderHis.trackingNumber && (
                                <Col className="col-12 d-flex justify-content-md-start justify-content-center align-items-center">
                                    <div onClick={() => openTrackingNumberClick(orderHis.trackingNumber)}>
                                        <b>Tracking#</b>:<span className="px-2 order-history__link">{orderHis.trackingNumber}</span>
                                    </div>
                                </Col>
                            )}
                            <Col className="col-12 d-flex flex-column">
                                <Row className="d-flex flex-column">
                                    <Col className="col-12 mt-2">
                                        <b>Item(s)</b>:{" "}
                                    </Col>
                                    {orderHis.items.map((item: any, i: number) => (
                                        <Col
                                            key={`${parentI}_${i}_items`}
                                            md={4}
                                            className="col-12 order-history__product_list_item p-2 border-radius border-bottom mt-2 d-flex align-items-center border"
                                        >
                                            {item.product.name} x {item.quantity}
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            <Col
                                md={12}
                                className="col-12 d-flex flex-column align-items-center align-items-md-end justify-content-center justify-content-md-end"
                            >
                                <div>
                                    Status: <b>{orderHis.order_status}</b>
                                </div>
                                {orderHis.shipped_on !== null && (
                                    <div>
                                        Shipped Date: <b>{new Date(orderHis.shipped_on).toLocaleDateString()}</b>
                                    </div>
                                )}
                                <div>
                                    Purchased Date: <b>{new Date(orderHis.ordered_on).toLocaleDateString()}</b>
                                </div>
                            </Col>
                        </Row>
                    );
                })
            ) : (
                <Col className="fs-5">Sorry for the inconvenience, the Order History tab has an issue that's come up. Please come back later. </Col>
            )}
            {limit < count && (
                <Row>
                    <Col>
                        <div>
                            <BootstrapButton
                                onClick={() => {
                                    setOffset(offset - limit);
                                    setBackButtonLoading(true);
                                }}
                                disabled={offset === 0 || nextButtonLoading}
                                loading={backButtonLoading}
                                className="m-2"
                                title="Back"
                            />
                            <BootstrapButton
                                onClick={() => {
                                    setOffset(offset + limit);
                                    setNextButtonLoading(true);
                                }}
                                loading={nextButtonLoading}
                                disabled={offset + limit >= count || backButtonLoading}
                                className="m-2"
                                title={`Next`}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};
