import { Col, Container, Row } from "react-bootstrap";
import { BootstrapInput } from "../../../components/BootstrapInput/BootstrapInput";
import { Member, MemberApi } from "../../../apis/MemberApi";
import { useAuthenticatedUser } from "../../../context/AuthContext";
import { BootstrapButton } from "../../../components/BootstrapButton/BootstrapButton";
import { toast } from "react-toastify";
import { useState } from "react";
import { AuthApi } from "../../../apis/AuthApi";

interface PasswordEmailResetrops {
    accountDetailsForm: Member;
}

export const PasswordEmailReset = ({ accountDetailsForm }: PasswordEmailResetrops) => {
    const { logout } = useAuthenticatedUser();
    const [newEmail, setNewEmail] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");


    const updateEmailClick = async () => {
        setEmailIsValid(true);
        setEmailErrorMessage("");
        if (accountDetailsForm) {
            let memberExistResponse = (await MemberApi.MemberAlreadyExists(newEmail.toLowerCase())) as any;
            if (memberExistResponse.success) {
                if (!memberExistResponse.emailExists) {
                    let response = await MemberApi.PatchOneMember(accountDetailsForm.member_id, { email: newEmail });
                    if (response.success) {
                        logout();
                    } else {
                        toast(response.message, { type: "error" });
                    }
                } else {
                    setEmailIsValid(false);
                    setEmailErrorMessage("Sorry, the email you've inputed is already in use.");
                }
            }
        }
    };

    const updatePasswordClick = async () => {
        if (currentPassword.length > 0 && newPassword.length > 0 && newPassword === confirmNewPassword) {
            let response = await AuthApi.ResetPasswordAuthenticated(currentPassword, newPassword).catch(console.error);
            if (response.success) {
                logout();
            } else {
                toast(response.message, { type: "error" });
            }
        }
    };

    return (
        <Container fluid className="mb-5 wrapper-tab d-block stage-container">
            <div className="wrapper-email py-4">
                <h5 className="fs-4 mb-4 fw-bold">Email</h5>
                <div className="fs-6 fw-bold text-danger">
                    Note: Changing your email will log you out and require you to go to your email and follow instructions. Until then your account
                    will be disabled.
                </div>
                <Row className="mt-3">
                    {!emailIsValid && (
                        <Col className="mb-2 tsn-red" sm={12} md={12}>
                            <div>{emailErrorMessage}</div>
                        </Col>
                    )}
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Email"
                            value={newEmail}
                            placeholder={accountDetailsForm.email}
                            valid={emailIsValid}
                            onChange={(e) => {
                                setNewEmail(e.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <BootstrapButton
                        disabled={
                            newEmail.length === 0 || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newEmail) || newEmail === accountDetailsForm.email
                        }
                        title="Update Email"
                        variant="secondary"
                        onClick={updateEmailClick}
                    />
                </div>
            </div>
            <div className="wrapper-password border-top py-4">
                <h5 className="fs-4 mb-4 fw-bold">Password</h5>

                <div className="fs-6 fw-bold text-danger mt-4 mb-3">
                    Note: Changing your password will log you out and require you to sign back in.
                </div>

                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Current Password"
                            value={currentPassword}
                            type="password"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={5}>
                        <BootstrapInput label="New Password" value={newPassword} type="password" onChange={(e) => setNewPassword(e.target.value)} />
                    </Col>

                    <Col sm={12} md={5}>
                        <BootstrapInput
                            label="Confirm New Password"
                            value={confirmNewPassword}
                            type="password"
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </Col>
                </Row>

                <div className="mt-4 mb-3">
                    <BootstrapButton
                        disabled={
                            currentPassword.length === 0 ||
                            newPassword.length === 0 ||
                            confirmNewPassword.length === 0 ||
                            newPassword !== confirmNewPassword
                        }
                        onClick={updatePasswordClick}
                        variant="secondary"
                        title="Update Password"
                    />
                </div>
            </div>
        </Container>
    );
};
