import { Accordion } from "../../components/Accordion/Accordion";

const accordionData = [
    {
        id: 1,
        heading: `<p><strong>What makes TopShelfNutra (TSN) product formulas unique?</strong></p>`,
        body: `<p>Several factors contribute to the amazing uniqueness and benefit people experience by using TSN products.&nbsp; First, the philosophy behind the formulation is always based upon a belief that the human body can repair, rebuild, and maintain itself.&nbsp; Except for cases where body parts and tissues need to be realigned, replaced, or reconstructed, our bodies have innate abilities to repair themselves.&nbsp; Talk with any surgeon, and they will tell you that their goal is to make whatever changes are necessary and then support the body in its efforts to repair and heal itself.&nbsp; TSN chooses the purest forms of ingredients that help support the body in this effort.&nbsp; So, though we don&rsquo;t make health claims that our products cure diseases, we do believe that when we provide the body with the building blocks it needs, it can more effectively heal itself than it could without these tools.&nbsp; TSN formulates products with the highest quality ingredients available and such that work synergistically together according to clinical research.&nbsp; In addition, TSN always creates sample batches of its formulas prior to producing a product to test and ensure that the expectations do in fact occur when the products are used or consumed.&nbsp; Many of our devoted customers have participated in the research sampling and because of their results, the products were produced and made available.&nbsp; TSN is not interested in selling products based simply upon the belief that certain nutrients should benefit the consumer.&nbsp; Instead, TSN stands behind the reality that after sampling our early formulas to various individuals, the results were measurable and dependable and therefore a product worthwhile bringing to the market.</p>`
    },
    {
        id: 2,
        heading: `<p><strong>If TopShelfNutra&rsquo;s (TSN) ingredients are of highest quality, how are they also providing the lowest price per serving?</strong></p>`,
        body: `<p>Product pricing is a variable strategy.&nbsp; Companies position and market themselves according to their specific and overall values. TSN&rsquo;s strategy is to be the lowest cost per serving provider of highest valued ingredients and formulations.&nbsp; Rather than packaging products for 30 servings, TSN products offer 60-90 servings per unit.&nbsp; This allows bulk buying of ingredients as well as reduced manufacturing and packaging costs per serving.&nbsp; TSN does not focus on expensive packaging, but rather a more utilitarian and environmentally friendly approach.&nbsp; 1 package per 2-3 months rather than 1 per month.&nbsp; TSN also has a reduced cost approach to marketing.&nbsp; TSN relies primarily upon word-of-mouth marketing by its happiest of customers.&nbsp; These factors and others play a role in providing the same quality of ingredients and products as have been offered to the market previously, but now at 40-50% reduced prices.&nbsp; This allows more people to access the amazing health benefits than was possible before.</p>`
    },
    {
        id: 3,
        heading: `<p><strong>What are some of the main ingredients TopShelfNutra (TSN) is known for?</strong></p>`,
        body: `<p>TSN products contain a variety of extremely beneficial nutrients.&nbsp; Each formula is proprietary and unique to TSN.&nbsp; We pride ourselves on both the formula, the unique sources and choice of ingredients, as well as the chosen form of delivery.&nbsp; There are a few ingredients that you will find in multiple of our formulas.&nbsp; We believe these ingredients are so key to overall health, that we include them in multiple products to increase a person&rsquo;s dosage and thereby benefit.&nbsp; These specific ingredients are naturally plant sourced and often add to the absorption and effectiveness of any product of which they are a part.</p>
<p>From the pristine volcanic soil mountain jungles of a remote island in Fiji we import <strong>Pink Fijian Ginger and Fijian Turmeric root powders</strong>.&nbsp; These come from heirloom roots discovered there and hand cultivated and processed in and on the organic island paddocks.&nbsp; Their unique chemical makeup and properties have been identified in laboratories and the unique health benefits are experienced daily by thousands of TSN members.</p>
<p>Carefully grown, harvested, and processed in the Southeastern US &ndash; the <strong>Muscadine Grape</strong> stands as the king of superfruits!&nbsp; The expansive list of phytonutrients found in the muscadine seeds and skins surpasses any other superfruit we know of today.&nbsp; Loaded with natural antioxidants, anti-inflammatories, polyphenols and more &ndash; TSN includes muscadine seed and skin extracts in multiple products.&nbsp; These compounds work synergistically with others to support the body&rsquo;s amazing abilities to boost immune function, repair damaged tissues, and improve overall functioning.</p>
<p>From ancient plant deposits discovered in Utah come a source of not fossilized organic <strong>Fulvic Plant Minerals</strong>.&nbsp; This deposit was captivated within layers of volcanic and sedimentary rock.&nbsp; Because of its unique location and the plants never turned to stone.&nbsp; Instead, the ancient plant matter remained preserved largely in its natural state.&nbsp; These plants contain 75+ trace minerals in a plant-derived form.&nbsp; The uniqueness of these molecules allows them to remain hydrophilic and water soluble.&nbsp; These trace minerals are therefore both highly absorbable and disposable.&nbsp; Our bodies can readily absorb what they need and eliminate easily what is not needed.&nbsp; Unlike other forms of metallic or fossilized mineral supplements that can be toxic if too much is consumed or absorbed, the water-soluble nature of TSN&rsquo;s plant-derived fulvic minerals make them safe and beneficial for human and animal consumption and are included in multiple TSN formulations.</p>
`
    },
    {
        id: 4,
        heading: `<p><strong>How do I access TopShelfNutra&rsquo;s (TSN) Member pricing and Free Shipping?</strong></p>`,
        body: `<p>TSN members can order what they want, whenever they want it!&nbsp; Shipping is free for all members regardless of what is ordered and the frequency.&nbsp; To become a member, one must agree to become a subscriber.
`
    },
    {
        id: 5,
        heading: `<p><strong>I&rsquo;m having trouble logging into my account, any suggestions?</strong></p>`,
        body: `<p>Making sure your account and membership subscription are active and current is necessary to enjoy all the benefits of TopShelfNutra membership.&nbsp; If you are having trouble logging into your account, it could also be a result of the cookies being stored from the last login.&nbsp; This can be especially problematic if someone else has logged into their account using your device.&nbsp; If you are having difficulty, try the following:</p>
<ol>
    <li>Close your web browser and reopen a new page</li>
    <li>Clear your Cache</li>
    <li>Navigate to www.topshelfnutra.com</li>
    <li>Click Login and enter your correct username and password</li>
</ol>
`
    },
    {
        id: 6,
        heading: `<p><strong>My shopping cart is showing Retail pricing rather than Member pricing.</strong><strong>&nbsp;&nbsp;</strong><strong>Why is this happening and what can I do to fix it?</strong></p>`,
        body: `<p>Member pricing and Free Shipping are available to all subscribing members.&nbsp; Setting up a login to our website is the first step, but you must also have an active subscription.&nbsp; If for any reason your monthly or annual subscription payment did not process, your member pricing and free shipping privileges will be put on hold.&nbsp; Simply update your stored payment method and pay your subscription and your member privileges will be restored.</p>`
    },
    {
        id: 7,
        heading: `<p><strong>How long does a typical shipment take to arrive to me?</strong></p>`,
        body: `<p>TSN puts great effort on shipping orders as soon as possible.&nbsp; Generally, expect 5-7 business days for your shipment to arrive, but we will try to beat that!&nbsp; Occasionally unforeseen circumstances can prevent packages from arriving within the normally expected delivery times.&nbsp;</p>`
    },
    {
        id: 8,
        heading: `<p><strong>How do I know which products to take for certain issues and what is the dosage?</strong></p>`,
        body: `<p>Each of TSN&rsquo;s product labels have indicator images to depict the structure function claims understood for the ingredients listed in the product.&nbsp; These are general suggestions and are not claims of diagnosis or treatment.&nbsp; We have many recordings on our YouTube channel if you just search for TopShelfNutra.&nbsp; These recordings can be very helpful in identifying which products would be appropriate for your specific concerns and interests.&nbsp; Also, each product label has helpful dosage recommendations.</p>`
    },
    {
        id: 9,
        heading: `<p><strong>How can I participate in referring others to TopShelfNutra (TSN) and qualifying for Product Discount Credits (PDC&rsquo;s)?</strong></p>`,
        body: `<p>Every TSN Member has a unique referral link.&nbsp; You can access, copy, and then share your unique link by logging into the website and accessing your account info.&nbsp; By going to &ldquo;My Account&rdquo; / &ldquo;Affiliate Program&rdquo; / &ldquo;Copy Link&rdquo;, you can then share the link with others.&nbsp; When they click on your link, the system will recognize them as having been referred by you.&nbsp; Every actively subscribed referral will produce 5 PDC&rsquo;s monthly to the referrer and additional PDC&rsquo;s to the tree of referrals.&nbsp; There are also PDC&rsquo;s associated with the Family Structure as well as certain Health Packs.&nbsp; More information can be found at the &rdquo;Affiliate Program&rdquo; link when logged into your account.</p>`
    },
    {
        id: 10,
        heading: `<p><strong>How do I redeem PDC&rsquo;s?</strong></p>`,
        body: `<p>Current PDC balances are always available when you are logged into your account and placing a product order.&nbsp; Your available PDC&rsquo;s are displayed at checkout and can be directly applied to the purchase price at 1PDC per $1 discount before sales tax is calculated and applied.&nbsp; If you accrue more than 200 PDC&rsquo;s and would like to earn a commission check, you can fill out the necessary tax documentation and cash out your PDC&rsquo;s at $1 per 1 PDC.</p>`
    },
    {
        id: 11,
        heading: `<p><strong>Are TopShelfNutra products Keto friendly?</strong></p>`,
        body: `<p>Keto diets and products have become both popular and controversial. Unfortunately, just stating on a label that a product is &ldquo;Keto friendly&rdquo; does not necessarily mean that it contains the correct percentages of macros. Our Vital Fuel Shake, SportnGo, JavaBoost coffee, Daily Detox Tea, as well as the other CupaHealth products are truly Keto-friendly in that they contain the correct ratios of fat to carbohydrates and proteins. We endorse an anti-inflammatory diet that is high in healthy fats, medium protein, and low carbohydrates. Calculating these ratios with every meal and product can prove difficult. We proudly represent that you can confidently incorporate all CupaHealth products into your Keto lifestyle without concerns of upsetting your macros or blood sugar goals. We also make available an amazing &ldquo;Make it Keto&rdquo; recipe book which has taken the challenge of calculating macros out of cooking. You will find many of your favorite foods in this recipe book that perfectly match the macro percentages needed for this anti-inflammatory lifestyle. We have personally tried each recipe and guarantee that you will be pleased with the foods made from them.</p>`
    }
];

export default function Faq() {
    return (
        <section style={{ minHeight: "86.7vh" }} className="container">
            <h2 className="section-title text-uppercase fw-bold my-5">FREQUENTLY ASKED QUESTIONS</h2>
            {accordionData.map((accordionData) => (
                <Accordion html item={accordionData} />
            ))}
        </section>
    );
}
